import React, { useState } from "react";
import styled from "styled-components";
import {
  ThreeColumnCard,
  ThreeColumnCard2,
  ThreeColumnContainer,
  ThreeColumnContainer2,
} from "./GlobalStyledComponents";
import Contact1 from "../images/svg/contact1.svg";
import Contact2 from "../images/svg/contact2.svg";
import Contact3 from "../images/svg/contact3.svg";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Frame = styled.div`
  margin-top: 25px;
  transform: translate(0, 6px);
  iframe {
    width: 100%;
    height: 590px;
    border: 0;
  }
`;
const TopSide = styled.div`
  margin-top: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    max-width: 800px;
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 2px;
    @media only screen and (max-width: 800px) and (min-width: 600px) {
      max-width: 600px;
    }
    @media only screen and (max-width: 600px) and (min-width: 320px) {
      max-width: 320px;
    }
    li {
      margin-right: 36px;
      span {
        background-color: rgba(31, 132, 197, 0.08);
        border-radius: 5px;
        padding: 4px 5px;
        font-family: "Rubik-Regular", sans-serif;
        font-size: 14px;
        margin-left: 12px;
        color: #1f84c5;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
      display: flex;
      align-items: center;
      .disable {
        color: #6f818f;
      }
      .active {
        color: #1f84c5;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: #1f84c5;
          bottom: -3px;
          left: 0;
          transition: transform 0.5s ease;
          transform: scaleX(0);
        }
        &:hover::after {
          transform: scaleX(1);
        }
        &::after {
          transform: scaleX(1);
        }
      }
      .noactive {
        color: #01304f;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: #1f84c5;
          bottom: -3px;
          left: 0;
          transition: transform 0.5s ease;
          transform: scaleX(0);
        }
        &:hover::after {
          transform: scaleX(1);
        }
        &:hover {
          color: #1f84c5;
        }
      }
      div {
        position: relative;
        font-family: "Rubik-Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
`;

const SubCard = styled.div`
  border: 1px solid #e2e6e9;
  border-radius: 18px;
  padding: 24px;
  display: flex;
  align-items: center;
  height: 120px;
  img {
    margin-right: 20px;
  }
  .text1 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #01304f;
    margin-bottom: 0;
  }
  .text2 {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 150%;
    color: #01304f;
    margin-bottom: 5px;
  }
`;

const OurContacts = () => {
  const [tab, setTab] = useState(1);
  const [list, setList] = useState([]);
  const handleTab = (i) => setTab(i);

  const { lan } = useSelector((state) => state);

  const { t } = useTranslation();

  const getContacts = () => {
    GetNotAuthInstance()
      .get(`/api/user/contact/?lan=uz`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data;
          if (Array.isArray(result)) {
            setList(result);
          } else {
            setList([]);
          }
        }
      })
      .catch(() => setList([]));
  };

  useEffect(() => {
    getContacts();
  }, []);

  const getLan = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <>
        <TopSide className="containerr">
          <p>{t("our_contacts.title")}</p>
          <ul>
            {list?.map((contact, index) => {
              const { country_en, country_ru, country_uz, status } = contact;
              return status === true ? (
                <li key={index}>
                  <div
                    className={tab === index + 1 ? "active" : "noactive"}
                    onClick={() => {
                      handleTab(index + 1);
                      setTab(index + 1);
                    }}
                  >
                    {getLan === "uz"
                      ? country_uz
                      : getLan === "ru"
                      ? country_ru
                      : getLan === "en"
                      ? country_en
                      : null}
                  </div>
                </li>
              ) : (
                <li key={index}>
                  <div className={tab === index + 1 ? "disable" : "disable"}>
                    {getLan === "uz"
                      ? country_uz
                      : getLan === "ru"
                      ? country_ru
                      : getLan === "en"
                      ? country_en
                      : null}
                  </div>
                  <span>{t("our_contacts.soon")}</span>
                </li>
              );
            })}
          </ul>
        </TopSide>

        {list?.map((contact, index) => {
          const {
            address_en,
            address_ru,
            address_uz,
            status,
            phone,
            email,
            location,
            short_location,
          } = contact;
          return status === true && tab === index + 1 ? (
            <React.Fragment key={index}>
              <ThreeColumnContainer2>
                <ThreeColumnCard2>
                  <a href={`tel: ${phone}`}>
                    <SubCard>
                      <img src={Contact1} alt="" />
                      <div className="">
                        <p className="text2">
                          {t("our_contacts.phone_number")}
                        </p>
                        <p className="text1">{phone}</p>
                      </div>
                    </SubCard>
                  </a>
                </ThreeColumnCard2>
                <ThreeColumnCard2>
                  <a href={`mailto: ${email}`}>
                    <SubCard>
                      <img src={Contact2} alt="" />
                      <div className="">
                        <p className="text2">{t("our_contacts.email")}</p>
                        <p className="text1">{email}</p>
                      </div>
                    </SubCard>
                  </a>
                </ThreeColumnCard2>
                <ThreeColumnCard2>
                  <a href={`${short_location}`} target="_blank">
                    <SubCard>
                      <img src={Contact3} alt="" />
                      <div className="">
                        <p className="text2">{t("our_contacts.address")}</p>
                        <p className="text1">
                          {getLan === "uz"
                            ? address_uz
                            : getLan === "ru"
                            ? address_ru
                            : getLan === "en"
                            ? address_en
                            : null}
                        </p>
                      </div>
                    </SubCard>
                  </a>
                </ThreeColumnCard2>
              </ThreeColumnContainer2>
              <Frame>
                <iframe
                  src={location}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Frame>
            </React.Fragment>
          ) : null;
        })}
      </>
    </>
  );
};

export default OurContacts;
