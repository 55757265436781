import {
  UPDATE_SCROLL,
  BASKET,
  FAVOURITE,
  CONVERTER,
  SUM,
  LOGIN,
  SET_USER,
  SET_LAN,
} from './types';

export const updateScroll = (scroll) => {
  return {
    type: UPDATE_SCROLL,
    scroll,
  };
};

export const setBasket = (basket) => {
  return {
    type: BASKET,
    basket,
  };
};

export const setFavourite = (favourite) => {
  return {
    type: FAVOURITE,
    favourite,
  };
};

export const setConverterVal = (converterVal) => {
  return {
    type: CONVERTER,
    converterVal,
  };
};

export const setSumConverter = (sumConverter) => {
  return {
    type: SUM,
    sumConverter,
  };
};

export const login = (login) => ({
  type: LOGIN,
  login,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});
export const setLan = (lan) => ({
  type: SET_LAN,
  lan,
});
