import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTransition } from "react";
import styled, { createGlobalStyle } from "styled-components";
import CloseSvg from "../images/svg/close.svg";

const Main = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  .sub {
    width: 573px;
    min-height: 10px;
    border-radius: 18px;
    border: 1px solid #888;
    border-color: #ccc;
    background: #fff;
    overflow: hidden;
    .close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      img {
        cursor: pointer;
        transition: 400ms;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    .t1main {
      padding: 30px 15px;
    }
    .t1 {
      font-family: "Rubik-Medium", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #01304f;
      text-align: center;
      margin-bottom: 0;
    }
    .t23main {
      & p:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const Layer = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background: #000;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
`;

const BodyHidden = createGlobalStyle`
      body{
            overflow: hidden;
      }
`;

const FormBtn = styled.div`
  .formBtn {
    position: relative;
    height: 48px;
    background: #1d4a8e;
    margin-bottom: -1px;
    border-radius: 0;
  }
`;

const ModalSuccess = (props) => {
  const { title, title2, title3, close, t } = props;

  // const [tick, setTick] = useState(0);

  // const maxLoad = 100;
  // const fulfillInterval = 3000;
  // const step = maxLoad / (fulfillInterval / 1000);

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     setTick((tick) => tick + step);
  //   }, 1000);

  //   setTimeout(() => {
  //     clearInterval(id);
  //   }, fulfillInterval + 1);

  //   return () => clearInterval(id);
  // }, []);

  return (
    <>
      <Layer />
      <Main>
        <div className="sub">
          {title != undefined ? (
            <div className="t1main">
              <p className="t1">{title}</p>
            </div>
          ) : null}
          <div className="t1main t23main">
            {title2 != undefined ? <p className="t1">{title2}</p> : null}
            {title3 != undefined ? <p className="t1">{title3}</p> : null}
          </div>

          {(title2 && title3) || title ? (
            <FormBtn>
              <button className="formBtn appbtn44" onClick={close}>
                {t("modal_info.ok")}
              </button>
            </FormBtn>
          ) : null}

          {/* <div>
            <div
              style={{
                height: "6px",
                background: "#1D4A8E",
                width: tick + "%",
              }}
            />
          </div> */}
        </div>
        <BodyHidden />
      </Main>
    </>
  );
};

export default ModalSuccess;
