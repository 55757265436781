import React, { useEffect, useState } from 'react';
import { ProductCard, ProductContainer } from '../GlobalStyledComponents';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { Products } from '../Catalog';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TopSide = styled.div`
  margin-top: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: 'Rubik-SemiBold', sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const PopularProducts = () => {
  const [popularProducts, setPopularProducts] = useState([]);

  const { lan } = useSelector((state) => state);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    GetNotAuthInstance()
      .get(`api/v1/product/?is_papular=True`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setPopularProducts(result);
          } else {
            setPopularProducts([]);
          }
        }
      })
      .catch((error) => {
        setPopularProducts([]);
      });
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [i18n.language]);
  return (
    <>
      {popularProducts.length > 0 ? (
        <>
          <TopSide className='containerr'>
            <p>{t('app.header_title2')}</p>
            <Link to='/catalog'>{t('app.show_all')}</Link>
          </TopSide>
          <ProductContainer>
            {popularProducts.map((popularProduct, index) => (
              <ProductCard key={index}>
                <Products {...popularProduct} />
              </ProductCard>
            ))}
          </ProductContainer>
        </>
      ) : null}
    </>
  );
};

export default PopularProducts;
