import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Logo2 from "../../images/svg/logo2.svg";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { useParams } from "react-router-dom";

const Main = styled.div`
  .card_main {
    margin-top: -32px;
    background: #1d4a8e;
    .card_sub {
      padding: 80px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;

      @media only screen and (max-width: 765px) and (min-width: 320px) {
        flex-direction: column;
        padding: 30px;
      }
      .left {
        width: 100%;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          display: flex;
          justify-content: center;
        }
        img {
          width: 275px;
          height: 85.12px;

          @media only screen and (max-width: 765px) and (min-width: 320px) {
            width: 70%;
          }
        }
      }
      .center {
        color: transparent;
        width: 1px;
        height: 120px;
        background: #1f84c5;
        margin-right: 40px;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          display: none;
        }
      }
      .right {
        font-family: "Rubik-Medium", sans-serif;
        font-size: 24px;
        line-height: 150%;
        color: #ffffff;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        p {
          width: 92%;

          @media only screen and (max-width: 765px) and (min-width: 320px) {
            width: 100%;
            margin-top: 24px;
            text-align: center;
            font-size: 20px;
            line-height: 130%;
          }
        }
      }
    }
  }
  .info {
    margin-top: 50px;
    .title {
      font-family: "Rubik-Medium", sans-serif;
      font-size: 18px;
      line-height: 21px;
      color: #01304f;
    }
    .text {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 150%;
      color: #6f818f;
      margin-bottom: 36px;
      &:nth-last-child(1) {
        margin-bottom: 50px;
      }
      img {
        margin-top: 15px;
        object-fit: cover;
        width: 100% !important;
      }
    }
  }
  .how_we_work {
    margin-bottom: 60px;
    .title {
      font-family: "Rubik-SemiBold", sans-serif;
      font-size: 32px;
      line-height: 38px;
      color: #01304f;
      margin-bottom: 46px;
    }
    .work_card {
      align-items: start;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, 378px);
      justify-content: center;
      @media (max-width: 1200px) and (min-width: 765px) {
        grid-template-columns: repeat(auto-fit, 363px);
      }

      @media only screen and (max-width: 765px) and (min-width: 320px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
      .sub {
        p {
          margin: 0;
        }
        span {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: #1f84c5;
          border-radius: 50%;
          font-family: "Rubik-Regular", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
        }
        .text1 {
          font-family: "Rubik-Medium", sans-serif;
          font-size: 18px;
          line-height: 21px;
          margin: 16px 0;
          color: #01304f;
        }
        .text2 {
          font-family: "Rubik-Regular", sans-serif;
          font-size: 16px;
          line-height: 150%;
          color: #01304f;
          margin-bottom: 36px;
        }
      }
    }
  }
`;

const NewsSingle = () => {
  const [obj, setObj] = useState({});
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GetNotAuthInstance()
      .get(`api/v1/news/${id}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const result = res?.data;
          if (result) {
            setObj(result);
          } else {
            setObj({});
          }
        }
      })
      .catch((error) => {
        setObj({});
      });
    window.scrollTo(0, 0);
  }, [i18n.language]);

  console.log(obj);

  return (
    <>
      <Main>
        <div className="card_main">
          <div className="card_sub containerr_small">
            <div className="left">
              <img src={Logo2} alt="" />
            </div>
            <div className="center">|</div>
            <div className="right">
              <p className="">{obj?.title}</p>
            </div>
          </div>
        </div>
        <div className="info containerr_small">
          <p className="title">{obj?.short_desc}</p>
          <img
            src={obj?.background_image}
            style={{ width: "100%", aspectRatio: "auto", paddingTop: "15px 0" }}
            alt=""
          />
          <br />
          <br />
          <div
            className="text text_single"
            dangerouslySetInnerHTML={{
              __html: obj?.body?.replaceAll('src="', 'src="https://api.ama.uz'),
            }}
          />
        </div>
      </Main>
    </>
  );
};

export default NewsSingle;
