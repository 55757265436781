import React from 'react';
import styled from 'styled-components';
import Logo from '../../images/svg/logo.svg';
import LogoGoogle from '../../images/svg/logo google.svg';
import { GoogleLogin } from 'react-google-login';
import { setToken } from '../../helpers/tokenStorage';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';
import { GetAuthInstance } from '../../helpers/httpClient';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login, setUser } from '../../redux';
import { useTranslation } from 'react-i18next';

const Main = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  .card {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #e5e5e5 !important;
    .card_sub {
      width: 474px;
      text-align: center;
      background: #ffffff;
      border-radius: 16px;
      padding: 65px 36px 40px 36px;
      .text1 {
        font-family: 'Rubik-SemiBold', sans-serif;
        margin-top: 59px;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 24px;
        color: #01304f;
      }
      .text2 {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        padding-left: 30px;
        padding-right: 30px;
      }
      .text3 {
        margin-top: 145px;
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        line-height: 24px;
        color: #6f818f;
      }
      .loginbtn_main {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 36px;
        button {
          width: 100% !important;
          display: flex !important;
          justify-content: center !important;
          padding: 13.5px !important;
          background: #ffffff !important;
          border: 1px solid #e2e6e9 !important;
          border-radius: 8px !important;
          justify-content: centerv;
          transition: 400msv;

          &:hover {
            background: #1d4a8e !important;

            span {
              color: #ffffff !important;
            }
          }
          div {
            background: transparent !important;
          }
          span {
            font-family: 'Rubik-Regular', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            color: #01304f;
          }
        }
      }
    }
  }
`;
const clientId =
  '549441864564-drqks28cg8r5co2aku67c0svf3mjvv0d.apps.googleusercontent.com';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', start);
  }, []);

  const loginWithGoogle = (data) => {
    dispatch(login(true));
    const formData = new FormData();
    formData.append('auth_token', data?.tokenId);
    GetAuthInstance()
      .post('api/user/google/', formData)
      .then((res) => {
        // window.location.reload();
        const user = res?.data?.user;
        const token = res?.data?.token;
        dispatch(setUser(user));
        window.localStorage.setItem('userInfo', user?.username);
        setToken(token, true);
        navigate('/');
      })
      .catch(() => {})
      .finally(() => {
        dispatch(login(false));
      });
  };

  // const onFailure = (res) => {
  //   console.log(res, 2);
  // };
  return (
    <Main>
      <div className='card'>
        <div className='card_sub'>
          <Link to='/'>
            <img src={Logo} alt='' />
          </Link>
          <p className='text1'>{t('login.welcome')}</p>
          <p className='text2'>{t('login.login_site')}</p>
          <div className='loginbtn_main'>
            <GoogleLogin
              className='loginbtn'
              clientId={clientId}
              onSuccess={loginWithGoogle}
              // onFailure={onFailure}
              buttonText={t('login.login_google')}
              cookiePolicy={'single_host_origin'}
              isSignedIn={true}
            />
          </div>
          <p className='text3'>{t('login.reserved')}</p>
        </div>
      </div>
    </Main>
  );
};

export default Login;
