import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { priceSplitter } from "../../helpers/priceSplitter";
import Loading from "../Loading";
import ModalSuccess from "../ModalSuccess";

const Main = styled.div`
  p {
    margin: 0;
  }
  .title {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #01304f;
    margin-bottom: 24px;
  }
  .text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:nth-child(2),
    &:nth-child(3) {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #6f818f;
      margin-bottom: 24px;
    }
    &:nth-child(4) {
      font-family: "Rubik-Medium", sans-serif;
      font-size: 18px;
      line-height: 21px;
      color: #01304f;
      margin-bottom: 24px;
    }
  }
  .appbtn {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 56px;
  }
`;

const BasketInfo = ({
  filterBasket,
  setActiveModal,
  submitProductCheckout,
  loading,
}) => {
  const { t } = useTranslation();

  const quantity = filterBasket.reduce(
    (total, drug) => total + drug.quantity,
    0
  );

  let moneyChange = window.localStorage.getItem("money");
  let sumConverter = window.localStorage.getItem("sumConverter");

  const token = window.localStorage.getItem("token");
  const totalPrice = priceSplitter(
    filterBasket.reduce(
      (total, { price, quantity }) =>
        total + (price * sumConverter).toFixed(0) * quantity,
      0
    )
  );

  return (
    <>
      {quantity !== 0 ? (
        <Main>
          <p className="title">{t("another.order_details")}</p>
          <p className="text">
            <span>{t("another.quantity_piece")}</span>
            <span>{quantity}</span>
          </p>
          <p className="text">
            <span>{t("another.goods_on")}</span>
            <span>
              {totalPrice}{" "}
              {moneyChange === "EUR" ? (
                <> EUR</>
              ) : moneyChange === "KZT" ? (
                <> KZT</>
              ) : moneyChange === "UZS" ? (
                <> UZS</>
              ) : (
                <> EUR</>
              )}
            </span>
          </p>
          <p className="text">
            <span>{t("another.total_payable")}</span>
            <span>
              {totalPrice}{" "}
              {moneyChange === "EUR" ? (
                <> EUR</>
              ) : moneyChange === "KZT" ? (
                <> KZT</>
              ) : moneyChange === "UZS" ? (
                <> UZS</>
              ) : (
                <> EUR</>
              )}
            </span>
          </p>
          {token ? (
            <>
              {loading ? (
                <button className="appbtn">
                  <div style={{ transform: "translate(0,2px)" }}>
                    <Loading />
                  </div>
                </button>
              ) : (
                <button
                  className="appbtn"
                  onClick={() => {
                    window.scroll(0, 0);
                    submitProductCheckout();
                  }}
                >
                  {t("another.pay")}
                </button>
              )}
            </>
          ) : (
            <button
              className="appbtn"
              onClick={() => {
                setActiveModal(false);
                window.scroll(0, 0);
              }}
            >
              {t("another.submit_your_application")}
            </button>
          )}
        </Main>
      ) : null}
    </>
  );
};

export default BasketInfo;
