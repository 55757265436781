import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Rubik-Bold", sans-serif;
  font-size: 24px;
  line-height: 130%;
  color: #01304f;
`;
const PageNFound = () => {
  const { t } = useTranslation();
  return (
    <Main>
      <div className="flexbox">{t("another.page_n_found")}</div>
    </Main>
  );
};

export default PageNFound;
