import { get } from 'lodash';
import {
  UPDATE_SCROLL,
  BASKET,
  FAVOURITE,
  CONVERTER,
  SUM,
  LOGIN,
  SET_USER,
  SET_LAN,
} from './types';

const initialState = {
  scroll: false,
  basket: [],
  favourite: [],
  lan: 'UZ',
  converterVal: 'EUR',
  sumConverter: '1',
  login: false,
  user: {},
  ordering: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCROLL:
      return {
        ...state,
        scroll: action.scroll,
      };
    case BASKET:
      return {
        ...state,
        basket: action.basket,
      };
    case FAVOURITE:
      return {
        ...state,
        favourite: action.favourite,
      };
    case CONVERTER:
      return {
        ...state,
        converterVal: action.converterVal,
      };
    case SUM:
      return {
        ...state,
        sumConverter: action.sumConverter,
      };
    case LOGIN:
      return {
        ...state,
        login: action.login,
      };
    case SET_USER:
      return {
        ...state,
        user: { ...state?.user, ...get(action, 'user', {}) },
        login: true,
      };
    case SET_LAN:
      return {
        ...state,
        lan: action.lan,
      };
    case 'SET_ORDERING': {
      return {
        ...state,
        ordering: action.payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
