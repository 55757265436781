import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Breadcrumb } from "../../components/GlobalStyledComponents";
import { BasketInfo, BasketProduct } from "../../components/Basket";
import { useSelector } from "react-redux";
import { getLocalStorage } from "../../helpers/localStorage";
import { GetAuthInstance, GetNotAuthInstance } from "../../helpers/httpClient";
import Bag from "../../images/svg/baskett2.svg";
import ReactPhoneInput from "react-phone-input-2";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import ModalSuccess from "../../components/ModalSuccess";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const Check = styled.div`
  .labelRadio2 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #01304f;
      transform: translate(0, 1px);
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        background-color: #1f84c5;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      border: 1px solid #e2e6e9;
      border-radius: 5px;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    &:hover input ~ .checkmark {
      border: 1px solid #1f84c5;
    }
  }
`;
const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;
const Main = styled.div`
  display: flex;
  margin-bottom: 100px;
  .leftt {
    width: calc(100% - 374px);
    padding-right: 24px;
  }
  .rightt {
    width: 374px;
  }
`;

const NotFound = styled.div`
  text-align: center;
  margin-bottom: 100px;
  p {
    margin: 20px 0;
  }
  .text1 {
    font-family: "Rubik-SemiBolds", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
  }
  .text2 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #01304f;
    margin-bottom: 40px;
  }
`;

const Basket = () => {
  const { basket, converterVal, user } = useSelector((state) => state);
  const [activeModal, setActiveModal] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [basketProducts, setBasketProducts] = useState([]);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [basketValid, setbasketValid] = useState({
    fullname: `${user.first_name} ${user.last_name}`,
    email: `${user.email}`,
    phone: `${user.phone}`,
  });
  const [errorValid, setErrorValid] = useState({});
  const [stat, setStat] = useState(false);
  const [dataId, setDataId] = useState("");
  const [basketList, setBasketList] = useState([]);

  const { t, i18n } = useTranslation();

  const tokenCheck = localStorage.getItem("token");

  const handleModalShow = () => setModalShow(!modalShow);

  const filterBasket = basketProducts
    .filter((basketProd) => {
      const check = basket.find((item) => item.id === basketProd.id);
      if (check) {
        return basketProd;
      }
    })
    .map((item) => {
      const quantity = basket.find((basketProd) => basketProd.id === item.id);
      if (quantity) {
        return { ...item, quantity: quantity.quantity };
      } else {
        return { ...item, quantity: 1 };
      }
    });

  const handleChanglePhone = (e) => {
    setbasketValid({
      ...basketValid,
      phone: e,
    });
  };

  const submitBasket = (e) => {
    e.preventDefault();
    // setLoading(true);

    let t = true,
      err = {};

    if (!basketValid.fullname) {
      err = { ...err, fullname: true };
      t = false;
    }
    // if (phone != 12) {
    //   err = { ...err, phone: true };
    //   t = false;
    // }
    if (!basketValid.email) {
      err = { ...err, email: true };
      t = false;
    }
    if (!basketValid.comment) {
      err = { ...err, comment: true };
      t = false;
    }

    const formData = new FormData();
    formData.append("fullname", get(basketValid, "fullname", ""));
    formData.append("email", get(basketValid, "email", ""));
    formData.append("phone", get(basketValid, "phone", ""));
    formData.append("comment", get(basketValid, "comment", ""));

    if (t) {
      const { basket } = getLocalStorage(["basket"]);
      const money = window.localStorage.getItem("money");

      const baskets = JSON.parse(basket) || [];
      if (baskets?.length) {
        const url = baskets?.reduce(
          (total, basketProduct, index) =>
            total +
            `p_id[]=${basketProduct?.id + ":" + basketProduct?.quantity}${
              index === baskets.length - 1 ? "" : "&"
            }`,
          `api/v1/user-application/?currency=${converterVal}&`
        );
        GetAuthInstance()
          .post(url, formData)
          .then((res) => {
            // const status = _.get(res, 'data.status');
            setBasketList([...basketList, res.formData]);
            window.localStorage.removeItem("basket");
            window.location.reload();
            window.scroll(0, 0);
          })
          .catch((e) => {
            // console.log(e);
          })
          .finally(() => {
            // setLoading(false);
          });
      }
    } else {
      window.scroll(0, 0);
      setErrorValid(err);
      // setLoading(false);
    }
  };

  const submitProductCheckout = (e) => {
    setLoading(true);
    const { basket } = getLocalStorage(["basket"]);
    const money = window.localStorage.getItem("money");
    const baskets = JSON.parse(basket) || [];
    if (baskets?.length) {
      const url = baskets?.reduce(
        (total, basketProduct, index) =>
          total +
          `p_id[]=${basketProduct?.id + ":" + basketProduct?.quantity}${
            index === baskets.length - 1 ? "" : "&"
          }`,
        `api/v1/order-create/?currency=${converterVal}&`
      );
      GetAuthInstance()
        .post(url)
        .then((res) => {
          let dataId = res?.data?.data?.id;
          if (res?.data?.status === 1) {
            submitPay(dataId);
          }
        })
        .catch((e) => {
          // console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const submitPay = (dataId) => {
    GetAuthInstance()
      .post(`api/v1/payment/get-payment-url/${dataId}/`)
      .then((res) => {
        let href = res?.data?.data?.octo_pay_url;
        if (res?.data?.data?.error === 0) {
          window.location.href = href;
          window.localStorage.removeItem("basket");
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    const { basket } = getLocalStorage(["basket"]);
    const baskets = JSON.parse(basket) || [];
    if (baskets?.length) {
      const url = baskets?.reduce(
        (total, basketProduct, index) =>
          total +
          `id=${basketProduct?.id}${index === baskets.length - 1 ? "" : "&"}`,
        "api/v1/product/?"
      );
      GetNotAuthInstance()
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data?.results;
            if (Array.isArray(result)) {
              setBasketProducts(result);
            } else {
              setBasketProducts([]);
            }
          }
        })
        .catch((e) => {})
        .finally(() => {});
    }
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {activeModal ? (
        <>
          <Breadcrumb>
            <ul className="containerr">
              <li>
                <Link to="/">{t("breadcrumb.main")}</Link>
              </li>
              <li>
                <span className="line"> / </span>
              </li>
              <li>
                <span className="disable">{t("breadcrumb.basket")}</span>
              </li>
            </ul>
          </Breadcrumb>
          <TopSide className="containerr">
            <p>{t("breadcrumb.basket")}</p>
          </TopSide>
          <div className="containerr">
            {filterBasket.length > 0 ? (
              <Main>
                <div className="leftt">
                  {filterBasket.map((b, index) => (
                    <BasketProduct key={index} {...b} />
                  ))}
                </div>
                <div className="rightt">
                  <BasketInfo
                    setActiveModal={setActiveModal}
                    filterBasket={filterBasket}
                    tokenCheck={tokenCheck}
                    submitProductCheckout={submitProductCheckout}
                    loading={loading}
                  />
                </div>
              </Main>
            ) : (
              <NotFound>
                <img
                  src={Bag}
                  style={{ width: "50px", height: "50px" }}
                  alt=""
                />
                <p className="text1">{t("basket.empty")}</p>
                <p className="text2">{t("basket.desc")}</p>
                <Link to="/catalog" className="appbtn">
                  {t("basket.catalog")}
                </Link>
              </NotFound>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="containerr_small2">
            <Breadcrumb>
              <ul className="containerr">
                <li>
                  <Link to="/">{t("breadcrumb.main")}</Link>
                </li>
                <li>
                  <span className="line"> / </span>
                </li>
                <li>
                  <Link to="/basket" onClick={() => setActiveModal(true)}>
                    {t("breadcrumb.catalog")}
                  </Link>
                </li>
                <li>
                  <span className="line"> / </span>
                </li>
                <li>
                  <span className="disable">{t("breadcrumb.review")}</span>
                </li>
              </ul>
            </Breadcrumb>

            <TopSide>
              <p> {t("breadcrumb.review")}</p>
            </TopSide>
            <div className="appForm">
              <form onSubmit={submitBasket}>
                <input
                  type="text"
                  name="fullname"
                  value={
                    basketValid?.fullname !== "undefined undefined"
                      ? basketValid?.fullname
                      : ""
                  }
                  placeholder={t("basket.fio")}
                  style={errorValid.fullname ? { borderColor: "#f00" } : {}}
                  onChange={(e) => {
                    setbasketValid({
                      ...basketValid,
                      fullname: e.target.value,
                    });
                    setErrorValid({ ...errorValid, fullname: false });
                  }}
                />
                <input
                  type="text"
                  name="email"
                  value={
                    basketValid.email != "undefined" ? basketValid.email : ""
                  }
                  placeholder={t("basket.email")}
                  style={errorValid.email ? { borderColor: "#f00" } : {}}
                  onChange={(e) => {
                    setbasketValid({
                      ...basketValid,
                      email: e.target.value,
                    });
                    setErrorValid({ ...errorValid, email: false });
                  }}
                />

                <ReactPhoneInput
                  value={user.phone}
                  onChange={(e) => handleChanglePhone(e)}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  autocomplete="off"
                  placeholder={t("breadcrumb.phone")}
                  country="uz"
                  containerStyle={{ marginTop: "15px" }}
                  onlyCountries={[
                    "uz",
                    "ru",
                    "kz",
                    "kg",
                    "az",
                    "tm",
                    "tj",
                    "af",
                  ]}
                  searchClass="search-class"
                  searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                  enableSearchField
                  disableSearchIcon
                />

                <textarea
                  name="comment"
                  value={basketValid.comment || ""}
                  placeholder={t("basket.comment")}
                  style={errorValid.comment ? { borderColor: "#f00" } : {}}
                  onChange={(e) => {
                    setbasketValid({
                      ...basketValid,
                      comment: e.target.value,
                    });
                    setErrorValid({ ...errorValid, comment: false });
                  }}
                />
                <button type="submit" className="appbtn">
                  {t("basket.send")}
                </button>
              </form>
            </div>
          </div>
        </>
      )}

      {modalShow === true ? (
        <ModalSuccess
          close={handleModalShow}
          title2={t("modal_info.congratulation")}
          title3={t("modal_info.congratulation_sub")}
          t={t}
        />
      ) : null}
    </>
  );
};

export default Basket;
