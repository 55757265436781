import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../components/GlobalStyledComponents";
import { GetAuthInstance } from "../../helpers/httpClient";
import PhoneInput from "react-phone-input-2";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { setUser } from "../../redux/store/action";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import Loading from "../../components/Loading";
import ModalSuccess from "../../components/ModalSuccess";

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const FormBtn = styled.div`
  .formBtn {
    position: relative;
    height: 48px;
  }
`;

const Profile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const { user } = useSelector((state) => state);

  const [errorValid, setErrorValid] = useState({});

  const { t } = useTranslation();

  const handleShowModal = () => setModalShow(!modalShow);

  const handleChanglePhone = (e) => {
    dispatch(
      setUser({
        ...user,
        phone: e,
      })
    );
  };

  const submitProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    let t = true,
      err = {};

    if (!user.first_name) {
      err = { ...err, first_name: true };
      t = false;
    }

    if (!user.last_name) {
      err = { ...err, last_name: true };
      t = false;
    }

    if (!user.email) {
      err = { ...err, email: true };
      t = false;
    }

    const formData = new FormData();
    formData.append("first_name", get(user, "first_name", ""));
    formData.append("last_name", get(user, "last_name", ""));
    formData.append("phone", get(user, "phone", ""));
    formData.append("email", get(user, "email", ""));

    if (t) {
      const userInfo = window.localStorage.getItem("userInfo");
      const url = `api/user/user/${userInfo}/`;
      if (userInfo) {
        GetAuthInstance()
          .put(url, formData)
          .then((res) => {
            dispatch(setUser(res?.data));
            window.scroll(0, 0);
            setModalShow(true);
          })
          .catch((e) => {})
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      window.scroll(0, 0);
      setErrorValid(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginBottom: "230px" }}>
      <div className="containerr_small2">
        <Breadcrumb>
          <ul className="containerr">
            <li>
              <Link to="/">{t("breadcrumb.main")}</Link>
            </li>
            <li>
              <span className="line"> / </span>
            </li>
            <li>
              <span className="disable">{t("another.profile")}</span>
            </li>
          </ul>
        </Breadcrumb>

        <TopSide>
          <p>{t("another.profile")}</p>
        </TopSide>
        <div className="appForm">
          <form onSubmit={submitProfile}>
            <input
              type="text"
              name="first_name"
              value={user.first_name || ""}
              placeholder={t("another.firstname")}
              style={errorValid.first_name ? { borderColor: "#f00" } : {}}
              onChange={(e) => {
                dispatch(
                  setUser({
                    ...user,
                    first_name: e.target.value,
                  })
                );
                setErrorValid({ ...errorValid, first_name: false });
              }}
            />
            <input
              type="text"
              name="last_name"
              value={user.last_name || ""}
              placeholder={t("another.lastname")}
              style={errorValid.last_name ? { borderColor: "#f00" } : {}}
              onChange={(e) => {
                dispatch(
                  setUser({
                    ...user,
                    last_name: e.target.value,
                  })
                );
                setErrorValid({ ...errorValid, last_name: false });
              }}
            />
            <PhoneInput
              value={user.phone}
              onChange={(e) => handleChanglePhone(e)}
              inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              autocomplete="off"
              placeholder={t("breadcrumb.phone")}
              country="uz"
              containerStyle={{ marginTop: "15px" }}
              onlyCountries={["uz", "ru", "kz", "kg", "az", "tm", "tj", "af"]}
              searchClass="search-class"
              searchStyle={{ margin: "0", width: "97%", height: "30px" }}
              enableSearchField
              disableSearchIcon
            />

            <input
              type="text"
              name="email"
              value={user.email || ""}
              placeholder={t("another.email")}
              style={errorValid.email ? { borderColor: "#f00" } : {}}
              onChange={(e) => {
                dispatch(
                  setUser({
                    ...user,
                    email: e.target.value,
                  })
                );
                setErrorValid({ ...errorValid, email: false });
              }}
            />
            <FormBtn>
              {loading ? (
                <button className="formBtn appbtn44">
                  <div style={{ transform: "translate(0,-8px)" }}>
                    <Loading />
                  </div>
                </button>
              ) : (
                <button type="submit" className="formBtn appbtn44">
                  {t("modal_info.save")}
                </button>
              )}
            </FormBtn>
          </form>
        </div>
      </div>
      {modalShow === true ? (
        <ModalSuccess
          close={handleShowModal}
          title={t("modal_info.saved_success")}
          t={t}
        />
      ) : null}
    </div>
  );
};

export default Profile;
