import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ThreeColumnCard,
  ThreeColumnContainer,
} from "../GlobalStyledComponents";

const SubCard = styled.div`
  border: 1px solid #e2e6e9;
  border-radius: 18px;
  padding: 23px;
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #01304f;
    margin-bottom: 10px;
    min-height: 30px;
  }
  .text2 {
    min-height: 90px;
    span {
      p {
        font-family: "Rubik-Regular", sans-serif;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        margin-bottom: 0;
      }
    }
  }
  .imgg {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
`;

const TopSide = styled.div`
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const Category = () => {
  const [getCatList, setGetCatList] = useState([]);
  const { t, i18n } = useTranslation();
  const [showItem, setShowItem] = useState(false);

  const showItems = () => {
    if (window.innerWidth > 992) {
      setShowItem(true);
    } else {
      setShowItem(false);
    }
  };

  const getCategories = () => {
    GetNotAuthInstance()
      // .get(`api/v1/category/?is_home=True`)
      .get(`api/v1/category`)
      .then((res) => {
        if (res.status === 200) {
          setGetCatList(res.data);
        }
      })
      .catch((error) => {
        setGetCatList([]);
      });
  };

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    showItems();
  }, []);

  window.addEventListener("resize", showItems);

  return (
    <>
      <TopSide className="containerr">
        {/* <p>{t("anothercatalogs")}</p> */}
      </TopSide>
      <ThreeColumnContainer>
        <ThreeColumnCard>
          <Link to={`/catalog/${get(getCatList, "0.slug")}`}>
            <SubCard>
              <p>{get(getCatList, "0.name")}</p>{" "}
              <div className="text2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: get(getCatList, "0.description"),
                  }}
                />
              </div>
              {get(getCatList, "0.image") ? (
                <img src={get(getCatList, "0.image")} className="imgg" alt="" />
              ) : null}
            </SubCard>
          </Link>
        </ThreeColumnCard>
        <ThreeColumnCard>
          <Link to={`/catalog/${get(getCatList, "1.slug")}`}>
            <SubCard>
              <p>{get(getCatList, "1.name")}</p>{" "}
              <div className="text2">
                <span
                  className="text1"
                  dangerouslySetInnerHTML={{
                    __html: get(getCatList, "1.description"),
                  }}
                />
              </div>
              {get(getCatList, "1.image") ? (
                <img src={get(getCatList, "1.image")} className="imgg" alt="" />
              ) : null}
            </SubCard>
          </Link>
        </ThreeColumnCard>
        <ThreeColumnCard>
          <Link to={`/catalog/${get(getCatList, "2.slug")}`}>
            <SubCard>
              <p>{get(getCatList, "2.name")}</p>{" "}
              <div className="text2">
                <span
                  className="text1"
                  dangerouslySetInnerHTML={{
                    __html: get(getCatList, "2.description"),
                  }}
                />
              </div>
              {get(getCatList, "2.image") ? (
                <img src={get(getCatList, "2.image")} className="imgg" alt="" />
              ) : null}
            </SubCard>
          </Link>
        </ThreeColumnCard>
        <ThreeColumnCard>
          <Link to={`/catalog/${get(getCatList, "3.slug")}`}>
            <SubCard>
              <p>{get(getCatList, "3.name")}</p>{" "}
              <div className="text2">
                <span
                  className="text1"
                  dangerouslySetInnerHTML={{
                    __html: get(getCatList, "3.description"),
                  }}
                />
              </div>
              {get(getCatList, "3.image") ? (
                <img src={get(getCatList, "3.image")} className="imgg" alt="" />
              ) : null}
            </SubCard>
          </Link>
        </ThreeColumnCard>
      </ThreeColumnContainer>
    </>
  );
};

export default Category;
