import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Main = styled.div`
  width: 100%;
  .searchform {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    /* border: 1px solid #e2e6e9; */
    padding: 8px 24px;
    border-radius: 8px;
    input {
      width: 100%;
      height: 36px;
      font-family: "Rubik-Regular", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .search_result {
    position: absolute;
    top: 58px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 99;
    .card {
      width: 100%;
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 8px;
      border: 1px solid #e2e6e9;
      background: #ffffff;
      box-shadow: 0px 1px 20px rgba(115, 141, 163, 0.1);
      border-radius: 8px;
      .not_found {
        font-family: "Rubik-Regular", sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        padding: 6px;
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 12px;
        background: #ffffff;
        text-align: center;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 12px;
        transition: 200ms;
        background: #ffffff;
        color: #000000;

        img {
          position: relative;
          width: 70px;
          height: 30px;
        }
        span {
          margin-left: 10px;
          font-family: "Rubik-Regular", sans-serif;
          font-size: 16px;
          line-height: 19px;
          border-radius: 8px;
        }
        &:hover {
          background: #f4f9fc;
          color: #1d4a8e;
          border-radius: 8px;
        }
      }
      /* @media only screen and (max-width: 920px) and (min-width: 320px) {
        width: calc(100% - 40px);
      } */
    }
  }
`;

const SearchContent = () => {
  const [searchh, setSearchh] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const { t, i18n } = useTranslation();

  const getByName = (
    page = 1,
    next_url = `api/v1/product/page=${page}&page_size=30`,
    search = ""
  ) => {
    let s = "";
    if (search) {
      s = "&search=" + search;
    }
    if (search !== "") {
      GetNotAuthInstance()
        .get(next_url + s)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data?.results;
            if (Array.isArray(result)) {
              setSearchResult(result);
            } else {
              setSearchResult([]);
            }
          }
        })
        .catch((err) => {
          setSearchResult([]);
        });
    } else {
      setSearchResult([]);
    }
  };

  const handleSearch = (e) => {
    setSearchh(e.target.value);
    let page = 1;
    let next_url = `api/v1/product/?page=${page}&page_size=30`;
    setTypingTimeOut(
      setTimeout(() => {
        getByName(page, next_url, e.target.value);
      }, 300)
    );

    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
  };

  useEffect(() => {
    getByName();
  }, [i18n.language]);

  return (
    <Main>
      <div className="searchform">
        <input
          type="text"
          onChange={handleSearch}
          value={searchh}
          placeholder={t("navbar.sublink2")}
        />
        <div className="search_result">
          {searchh !== "" && searchResult?.length > 0 ? (
            <div className="card">
              {searchResult?.map((item, index) => {
                const { slug, name } = item;
                return (
                  <Link
                    to={`/catalog-single/${slug}`}
                    key={index}
                    onClick={() => {
                      searchh("");
                      searchResult([]);
                    }}
                  >
                    <span>{name}</span>
                  </Link>
                );
              })}
            </div>
          ) : searchh !== "" ? (
            <div className="card">
              <div className="not_found">{t("navbar.searchtext")}</div>
            </div>
          ) : null}
        </div>
      </div>
    </Main>
  );
};

export default SearchContent;
