import React, { useEffect, useState } from "react";
import Product from "../../images/product.png";
import Loved from "../../images/svg/Loved.svg";
import Loved2 from "../../images/svg/Loved2.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import {
  ProductCard,
  ProductContainer,
} from "../../components/GlobalStyledComponents";
import Bag from "../../images/svg/Bag.svg";
import { useTranslation } from "react-i18next";

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const Single = () => {
  const [btn, setBtn] = useState(true);
  const [fav, setFav] = useState(true);

  const [recentList, setRecentList] = useState([]);

  const { t, i18n } = useTranslation();

  const getRecents = () => {
    let recent = window.localStorage.getItem("recent");
    recent = JSON.parse(recent?.length ? recent : "[]");

    let str = "";
    recent.forEach((item) => {
      str += `&id=${item}`;
    });

    GetNotAuthInstance()
      .get(`api/v1/product/?page=1&page_size=4${str}`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setRecentList(result);
          } else {
            setRecentList([]);
          }
        }
      })
      .catch((error) => {
        setRecentList([]);
      });
    // .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    getRecents();
    window.scrollTo(0, 0);
  }, [i18n.language]);

  return (
    <>
      <TopSide className="containerr">
        <p>{t("another.recently_watched")}</p>
      </TopSide>
      <ProductContainer>
        {recentList.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item?.id} <br />
              {item?.name}
            </React.Fragment>
            // <ProductCard key={index}>
            //   <Link
            //     to='/catalog/single'
            //     onClick={() => {
            //       window.scrollTo(0, 0);
            //     }}
            //   >
            //     <div className='product_main'>
            //       <img src={Product} alt='' />
            //     </div>
            //   </Link>
            //   {fav ? (
            //     <img
            //       src={Loved}
            //       className='fav'
            //       onClick={() => setFav(false)}
            //       alt=''
            //     />
            //   ) : (
            //     <img
            //       src={Loved2}
            //       className='fav'
            //       onClick={() => setFav(true)}
            //       alt=''
            //     />
            //   )}
            //   <div className='info'>
            //     <Link
            //       to='/catalog/single'
            //       onClick={() => {
            //         window.scrollTo(0, 0);
            //       }}
            //     >
            //       <p>Brauberg</p>
            //       <p>{item?.name}</p>
            //       <p>
            //         от <span>28 500 сум</span>
            //       </p>
            //     </Link>
            //     {btn ? (
            //       <button className='appbtn' onClick={() => setBtn(false)}>
            //         <img
            //           src={Bag}
            //           style={{
            //             marginRight: '10px',
            //             transform: 'translate(0,-2px)',
            //           }}
            //           alt=''
            //         />
            //         В корзину
            //       </button>
            //     ) : (
            //       <button className='appbtn2' onClick={() => setBtn(true)}>
            //         Добавлено
            //       </button>
            //     )}
            //   </div>
            // </ProductCard>
          );
        })}
      </ProductContainer>
    </>
  );
};

export default Single;
