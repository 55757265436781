import React from 'react';
import styled from 'styled-components';
import Logo2 from '../images/svg/logo2.svg';
import Social from '../images/svg/social.svg';
import Social2 from '../images/svg/social2.svg';
import Social3 from '../images/svg/social3.svg';

import {
  FourColumnCard,
  FourColumnContainer,
} from '../components/GlobalStyledComponents';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { GetNotAuthInstance } from '../helpers/httpClient';

const Main = styled.div`
  padding: 58px 0 65px 0;
  background: #01304f;
  .ul1,
  .ul2 {
    list-style-type: none;
  }
  .ul1 {
    display: flex;
    margin-top: 48px;
    li {
      margin-right: 20.38px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
  .ul2 {
    li {
      margin-bottom: 16px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      a {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 16px;
        line-height: 26px;
        color: #e2e6e9;
        width: 100%;
        display: flex;
      }

      &:nth-child(1) {
        font-family: 'Rubik-Medium', sans-serif;
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
`;
const SubFooter = styled.div`
  font-family: 'Rubik-Regular', sans-serif;
  text-align: center;
  padding: 36px;
  font-size: 18px;
  line-height: 24px;
  color: #e2e6e9;
  background: #01304f;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #6f818f;
    opacity: 0.2;
  }
`;

const Footer = () => {
  const [getCatList, setGetCatList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    GetNotAuthInstance()
      .get(`api/v1/category/`)
      .then((res) => {
        if (res.status === 200) {
          setGetCatList(res.data);
        }
      })
      .catch((error) => {
        setGetCatList([]);
      });
  };

  return (
    <>
      <Main>
        <FourColumnContainer>
          <FourColumnCard>
            <Link to='/'>
              <img src={Logo2} alt='' />
            </Link>
            <ul className='ul1'>
              <li>
                <a href='' target='_blank'>
                  <img src={Social} alt='' />
                </a>
              </li>
              <li>
                <a href='' target='_blank'>
                  <img src={Social2} alt='' />
                </a>
              </li>
              <li>
                <a href='' target='_blank'>
                  <img src={Social3} alt='' />
                </a>
              </li>
            </ul>
          </FourColumnCard>
          <FourColumnCard>
            <ul className='ul2'>
              <li>{t('footer.company')} “Ama”</li>
              <li>
                <Link to='/about-us'>{t('footer.about_us')}</Link>
              </li>
              {/* <li>
                <Link to='/'>Публичная оферта</Link>
              </li>
              <li>
                <Link to='/'>Политика конфиденциальности</Link>
              </li> */}
              <li>
                <Link to='/contacts'>{t('footer.contacts')}</Link>
              </li>
            </ul>
          </FourColumnCard>
          <FourColumnCard>
            <ul className='ul2'>
              <li>{t('footer.categories')}</li>
              {getCatList.map((cat, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={`/catalog/${cat.slug}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {cat.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </FourColumnCard>
          <FourColumnCard>
            <ul className='ul2'>
              <li>{t('footer.contacts')}</li>
              <li>
                <a href='tel: +998 90 174-85-87'>+998 90 174-85-87</a>
              </li>
              <li>
                <a href='mailto: info@ama.uz'>info@ama.uz</a>
              </li>
              <li>
                <a href='https://goo.gl/maps/MPxCLp4Ss7N1DA9t8' target='_blank'>
                  {t('footer.address')}
                </a>
              </li>
            </ul>
          </FourColumnCard>
        </FourColumnContainer>
      </Main>

      <SubFooter>{t('footer.sub')}</SubFooter>
    </>
  );
};

export default Footer;
