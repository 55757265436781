import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HomeBg from "../../images/homebg.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetNotAuthInstance } from "../../helpers/httpClient";

const Main = styled.div`
  position: relative;
  padding: 114px 0 0 0;

  .slick-list {
    padding-bottom: 35px;
  }
  .slick-dots {
    transform: translate(0, 30px);
    bottom: 24px;
    ul {
      li {
        margin: 0;
      }
    }
    .custompage {
      background: #cad3df;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .slick-active {
      width: 50px;
      margin-right: 5px;
      overflow: hidden;
      .custompage {
        transition: 250ms ease-in-out;
        overflow: hidden;
        position: relative;
        background: #cad3df;
        width: 50px;
        height: 10px;
        border-radius: 8px;
      }
    }
  }
`;
const MainSub = styled.div`
  min-height: 204px;
  position: relative;
  border-radius: 32px;
  padding: 123px 188px;
  @media (max-width: 1100px) and (min-width: 992px) {
    padding: 123px 88px;
  }
  @media (max-width: 992px) and (min-width: 576px) {
    padding: 123px 25px;
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding: 70px 25px;
  }
  .info {
    position: relative;
    z-index: 1;
    text-align: center;
    p {
      &:nth-child(1) {
        font-family: "Rubik-Bold", sans-serif;
        font-size: 36px;
        line-height: 150%;
        color: #01304f;
        @media only screen and (max-width: 765px) and (min-width: 320px) {
          font-size: 26px;
          line-height: 130%;
        }
      }
      &:nth-child(2) {
        font-family: "Rubik-Regular", sans-serif;
        margin-top: 10px;
        margin-bottom: 56px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        padding: 0 70px;
        @media only screen and (max-width: 765px) and (min-width: 320px) {
          padding: 0;
        }
      }
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 32px;
  }
`;
const GoToCatalog = () => {
  const [list, setList] = useState([]);
  const { t, i18n } = useTranslation();

  const getSlider = () => {
    GetNotAuthInstance()
      .get(`api/v1/news`)
      .then((res) => {
        if (res.status === 200) {
          setList(res.data);

          const urlHash = window.location.hash;
          if (urlHash.substring(1) === "news") {
            setTimeout(() => {
              if (urlHash.length) {
                const element = document.getElementById(urlHash.substring(1));
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }, 250);
          }
        }
      })
      .catch((error) => {
        setList([]);
      });
  };

  useEffect(() => {
    getSlider();
  }, [i18n.language]);

  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    spaceBetween: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="custompage"></div>,
  };
  return (
    <section className="containerr" id="news">
      <Main>
        <Slider {...settings}>
          {list?.map((item, index) => (
            <MainSub key={index}>
              <img src={item?.background_image} alt="" />
              <div className="info">
                <p>{item?.title}</p>
                <p>{item?.body}</p>
                <Link to={`/news/${item?.id}`} className="appbtn">
                  {t("another.go_to_catalogs")}
                </Link>
              </div>
            </MainSub>
          ))}
        </Slider>
      </Main>
    </section>
  );
};

export default GoToCatalog;
