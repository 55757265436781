import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Mainn } from '../../components/GlobalStyledComponents';
import {
  Category,
  PopularProducts,
  SpecialOffers,
  OurAdvantages,
  GoToCatalog,
  OurReviews,
} from '../../components/Home';
import OurContacts from '../../components/OurContacts';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Category />
      <SpecialOffers />
      <PopularProducts />
      <OurAdvantages />
      <GoToCatalog />
      <OurReviews />
      <OurContacts />
    </>
  );
};

export default Home;
