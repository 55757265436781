import React, { useEffect, useState } from "react";
import Product from "../../images/product.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  TwoColumnCard,
  TwoColumnContainer,
  TwoColumnMain,
  TwoColumnTop,
} from "../../components/GlobalStyledComponents";
import { useTranslation } from "react-i18next";
import { GetAuthInstance, GetNotAuthInstance } from "../../helpers/httpClient";
import Moment from "react-moment";
import { get } from "lodash";
import Bag from "../../images/svg/baskett2.svg";

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const NotFound = styled.div`
  text-align: center;
  margin-bottom: 100px;
  p {
    margin: 20px 0;
  }
  .text1 {
    font-family: "Rubik-SemiBolds", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
  }
  .text2 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #01304f;
    margin-bottom: 40px;
  }
`;

const PurchaseHistory = () => {
  const [waitingStatus, setWaitingStatus] = useState(1);
  const [historyPay, setHistoryPay] = useState([]);
  const [nextUrlHistoryPay, setNextUrlHistoryPay] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    getHistoryPayment();
    window.scrollTo(0, 0);
  }, []);

  const getHistoryPayment = (
    page = 1,
    next_url = `api/v1/order/?page=${page}&page_size=10`
  ) => {
    if (next_url) {
      GetAuthInstance()
        .get(next_url)
        .then((res) => {
          if (res.status === 200) {
            const result =
              page === 1
                ? res?.data?.results
                : [...historyPay, ...res?.data?.results];
            if (Array.isArray(result)) {
              setHistoryPay(result);
              setNextUrlHistoryPay(res?.data?.next);
            } else {
              setHistoryPay([]);
            }
          }
        })
        .catch((err) => {
          setHistoryPay([]);
        })
        .finally(() => {});
    }
  };

  console.log(historyPay);

  return (
    <>
      <Breadcrumb>
        <ul className="containerr">
          <li>
            <Link to="/">{t("breadcrumb.main")}</Link>
          </li>
          {/* <li>
            <span className="line"> / </span>
          </li>
          <li>
            <Link to="/">{t("breadcrumb.accountt")}</Link>
          </li> */}
          <li>
            <span className="line"> / </span>
          </li>
          <li>
            <span className="disable">{t("breadcrumb.history_purchase")}</span>
          </li>
        </ul>
      </Breadcrumb>
      <TopSide className="containerr">
        <p>{t("breadcrumb.history_purchase")}</p>
      </TopSide>
      <div className="containerr">
        {historyPay?.length > 0 ? (
          <>
            {historyPay?.map((item, index) => {
              const { id, created_at, order_items, total, currency, status } =
                item;
              return (
                <TwoColumnMain key={index}>
                  <TwoColumnTop>
                    <div className="header">
                      <div className="sub">
                        <p>#{id}</p>
                        <p>
                          {total} {currency}
                        </p>
                      </div>
                      <div className="sub2">
                        <p>
                          <Moment format="DD/MM/YYYY">{created_at}</Moment> •{" "}
                          {order_items?.length}{" "}
                          {order_items?.length > 1
                            ? t("pay_history.prods")
                            : t("pay_history.prod")}
                          {/* • 8600 **** **** 9382 */}
                        </p>
                        <p>
                          {status === "pending" ? (
                            <span className="orange">{t("purchase.wait")}</span>
                          ) : status === "accept" ? (
                            <span className="green">{t("purchase.paid")}</span>
                          ) : status === "error" ? (
                            <span className="red">{t("purchase.calncel")}</span>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </TwoColumnTop>
                  <TwoColumnContainer>
                    {order_items?.map((order_item, index) => {
                      const { product, product_count } = order_item;
                      return (
                        <TwoColumnCard key={index}>
                          <img
                            src={get(product, "images[0].image", [])}
                            className="productimg"
                            alt=""
                          />
                          <div className="">
                            <p>{get(product, "name")}</p>
                            <p>
                              {product_count} {t("another.piece_by")}{" "}
                              {total / product_count} {currency}
                            </p>
                          </div>
                        </TwoColumnCard>
                      );
                    })}
                  </TwoColumnContainer>
                </TwoColumnMain>
              );
            })}
          </>
        ) : (
          <NotFound>
            <img src={Bag} style={{ width: "50px", height: "50px" }} alt="" />
            <p className="text1">{t("breadcrumb.history_purchase_empty")}</p>
            <p className="text2">{t("basket.desc")}</p>
            <Link to="/catalog" className="appbtn">
              {t("basket.catalog")}
            </Link>
          </NotFound>
        )}
      </div>
    </>
  );
};

export default PurchaseHistory;
