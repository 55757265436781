import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Breadcrumb,
  ThreeColumnCard,
  ThreeColumnContainer,
} from "../../components/GlobalStyledComponents";
import { Filter, Products } from "../../components/Catalog";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  .results {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    color: #1f84c5;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 80px;
  .left {
    width: 315px;

    @media only screen and (max-width: 992px) and (min-width: 320px) {
      display: none;
    }
  }
  .right {
    width: calc(100% - 315px);
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      width: 100%;
    }
  }
`;

const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
`;

const NoProduct = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  padding: 0 15px;
  font-family: "Rubik-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #01304f;
`;

const Catalog = () => {
  const [deliveryPeriod, setDeliveryPeriod] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [drugsToBasket, setDrugsToBasket] = useState([]);
  const [fTest, setFTest] = useState("");
  const [preLoading, setPreLoading] = useState(false);
  const [nextUrlDrugsToBasket, setNextUrlDrugsToBasket] = useState("");
  const [findItem, setFindItem] = useState(false);
  const [closeMenu, setCloseMenu] = useState(false);
  const [filters, setFilters] = useState({
    delivery_period: [],
    manufacturer: [],
    min_price: [],
    max_price: [],
  });

  const { slugg } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const changeFilter = (name = "", value, is_single = false) => {
    let f = { ...filters };

    if (is_single) {
      if (name === "_price") {
        const vs = value?.split("-");
        f = { ...f, min_price: [vs[0]] };
        if (vs[1] !== "Max") {
          f = { ...f, max_price: [vs[1]] };
        } else {
          delete f.max_price;
        }
      } else {
        f = { ...f, [name]: [value] };
      }
    } else {
      if (get(f, name, []).includes(value)) {
        let l = [];
        get(f, name, []).forEach((i) => {
          if (i !== value) l.push(i);
        });
        f = { ...f, [name]: l };
      } else {
        f = { ...f, [name]: [...get(f, name, []), value] };
      }
    }
    setFilters(f);
    let query = "/catalog/?";
    for (const property in f) {
      let s = "";
      f[property].forEach((item) => {
        s += item + ",";
      });
      s = s.slice(0, -1);
      if (s) query += `&${property}=${s}`;
    }
    navigate(query);
  };

  const clearFilter = () => {
    let query = "/catalog?";
    navigate(query);
    window.location.reload();
  };

  const getDeliveryPeriod = () => {
    GetNotAuthInstance()
      .get("api/v1/delivery-period")
      .then((res) => {
        setDeliveryPeriod([...res.data]);
      })
      .catch((err) => {})
      .finally(() => {});
  };
  const getManufacturer = () => {
    GetNotAuthInstance()
      .get("api/v1/manufacturer")
      .then((res) => {
        setManufacturer([...res.data]);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const getDrugsToBasket = (page = 1, next_url = ``) => {
    const windowUrl = window.location.search;
    const newStr = windowUrl.replaceAll(",", "&manufacturer=");
    setPreLoading(true);
    const urrr = next_url
      ? next_url
      : `api/v1/product/${newStr ? newStr : ""}${
          newStr ? "&" : "?"
        }page=${page}&page_size=10`;
    GetNotAuthInstance()
      .get(urrr)
      .then((res) => {
        if (res.status === 200) {
          const result =
            page === 1
              ? res?.data?.results
              : [...drugsToBasket, ...res?.data?.results];
          if (Array.isArray(result)) {
            setDrugsToBasket(result);
            setNextUrlDrugsToBasket(res?.data?.next);
          } else {
            setDrugsToBasket([]);
          }
        }
      })
      .catch((err) => {
        setDrugsToBasket([]);
      })
      .finally(() => setPreLoading(false));
  };

  const getFindItem = () => {
    if (window.innerWidth > 992) {
      setFindItem(true);
    } else {
      setFindItem(false);
    }
  };

  const handleCloseMenu = (i) => setCloseMenu(i);

  let getSlugProducts = [];
  drugsToBasket.forEach((item) => {
    if (item?.category?.slug === slugg) {
      getSlugProducts.push(item);
    }
  });

  useEffect(() => {
    getDeliveryPeriod();
    getManufacturer();
  }, [i18n.language]);

  useEffect(() => {
    let f = {};
    const s = [...searchParams];
    s.forEach((item) => {
      const [n, vl] = item;
      const tl = ["delivery_period", "manufacturer", "min_price", "max_price"];
      let vll = [];
      if (tl.includes(n))
        vl?.split(",").forEach((itemp) => {
          vll.push(itemp);
        });
      else vll = vl?.split(",");
      if (n && vl) f = { ...f, [n]: vll };
    });
    setFilters(f);

    getDrugsToBasket();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [i18n.language, window.location.search]);

  useEffect(() => {
    getFindItem();
    window.scrollTo(0, 0);
  }, []);

  window.addEventListener("resize", getFindItem);

  return (
    <>
      <Breadcrumb>
        <ul className="containerr">
          <li>
            <Link to="/">{t("breadcrumb.main")}</Link>
          </li>
          <li>
            <span className="line"> / </span>
          </li>
          <li>
            <span className="disable">
              {slugg ? (
                <>
                  {getSlugProducts?.length
                    ? get(getSlugProducts[0], "category.name")
                    : t("breadcrumb.no_detail")}
                </>
              ) : (
                t("breadcrumb.no_catalog_product")
              )}
            </span>
          </li>
        </ul>
      </Breadcrumb>
      <TopSide className="containerr">
        <p>
          {slugg ? (
            <>
              {getSlugProducts?.length ? (
                <>
                  {get(getSlugProducts[0], "category.name")}
                  <p className="results">
                    {getSlugProducts?.length} {t("breadcrumb.results")}
                  </p>
                </>
              ) : (
                t("breadcrumb.no_detail")
              )}
            </>
          ) : (
            t("breadcrumb.no_catalog_product")
          )}
        </p>
      </TopSide>
      <div className="containerr">
        {!findItem ? (
          <button
            className="appbtn"
            onClick={handleCloseMenu}
            style={{
              padding: "10px 20px",
            }}
          >
            Filter
          </button>
        ) : null}
        <div
          className="closeMenuMainBack"
          onClick={() => handleCloseMenu(false)}
          data-active={closeMenu ? "active" : "inactive"}
        ></div>
        <div
          className="closeMenuMain"
          style={{
            paddingLeft: "10px",
          }}
          data-active={closeMenu ? "active" : "inactive"}
        >
          <div className="svgMain">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onClick={() => handleCloseMenu(false)}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="#000"
              />
            </svg>
          </div>
          <Filter
            clearFilter={clearFilter}
            filters={filters}
            deliveryPeriod={deliveryPeriod}
            manufacturer={manufacturer}
            changeFilter={changeFilter}
          />
        </div>

        <Flex>
          <div className="left">
            <Filter
              clearFilter={clearFilter}
              filters={filters}
              deliveryPeriod={deliveryPeriod}
              manufacturer={manufacturer}
              changeFilter={changeFilter}
            />
          </div>
          <div className="right">
            <ThreeColumnContainer>
              {slugg ? (
                <>
                  {getSlugProducts?.length ? (
                    <>
                      {getSlugProducts.map((getToBasket, index) => (
                        <ThreeColumnCard key={index}>
                          <Products {...getToBasket} />
                        </ThreeColumnCard>
                      ))}
                    </>
                  ) : (
                    <NoProduct> {t("prod.no_prod")}</NoProduct>
                  )}
                </>
              ) : (
                <>
                  {drugsToBasket?.length ? (
                    <>
                      {drugsToBasket.map((drugToBasket, index) => (
                        <ThreeColumnCard key={index}>
                          <Products {...drugToBasket} />
                        </ThreeColumnCard>
                      ))}
                    </>
                  ) : (
                    <NoProduct>{t("prod.no_prod")}</NoProduct>
                  )}
                </>
              )}
            </ThreeColumnContainer>

            <ShowMore>
              {preLoading ? (
                <>loading</>
              ) : (
                <>
                  {nextUrlDrugsToBasket ? (
                    <button
                      className="appbtn"
                      onClick={() => getDrugsToBasket(2, nextUrlDrugsToBasket)}
                    >
                      {t("breadcrumb.show_more")}
                    </button>
                  ) : (
                    ""
                  )}
                </>
              )}
            </ShowMore>
          </div>
        </Flex>
      </div>
    </>
  );
};

export default Catalog;
