import { get } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GetNotAuthInstance } from "../../helpers/httpClient";

const Main = styled.div`
  padding-right: 20px;
  .step {
    margin-bottom: 24px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .appbtn2 {
    width: 100%;
    margin-top: 20px;
  }
  .title {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #01304f;
    margin-bottom: 16px;
  }
  .range {
    display: flex;
    justify-content: space-between;
    width: 100%;
    input {
      border: none;
      border: 1px solid #e2e6e9;
      border-radius: 8px;
      width: 50%;
      height: 51px;
      padding-left: 16px;
      padding-right: 16px;
      color: #01304f;
      &:nth-child(1) {
        margin-right: 15px;
      }
      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        font-family: "Rubik-Regular", sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #6f818f;
      }
    }
  }

  .labelRadio,
  .labelRadio2 {
    span {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #01304f;
      transform: translate(0, 2px);
    }
  }
  .labelRadio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        background-color: #1f84c5;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      border-radius: 50%;
      border: 1px solid #e2e6e9;
      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 5.5px;
        left: 5.5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
    &:hover input ~ .checkmark {
      border: 1px solid #1f84c5;
    }
  }
  .labelRadio2 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        background-color: #1f84c5;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      border: 1px solid #e2e6e9;
      border-radius: 5px;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    &:hover input ~ .checkmark {
      border: 1px solid #1f84c5;
    }
  }
`;
const Filter = ({
  filters = {},
  deliveryPeriod,
  manufacturer,
  changeFilter,
  clearFilter,
}) => {
  const { t } = useTranslation();
  return (
    <Main>
      <div className="step">
        <p className="title">{t("filter.price")}</p>
        <div className="range">
          <input
            type="number"
            placeholder={t("filter.from")}
            value={get(filters, "min_price[0]", "")}
            onChange={(e) => changeFilter("min_price", e.target.value, true)}
          />
          <input
            type="number"
            placeholder={t("filter.to")}
            value={get(filters, "max_price[0]", "")}
            onChange={(e) => changeFilter("max_price", e.target.value, true)}
          />
        </div>
      </div>
      <div className="step">
        <p className="title">{t("filter.date_deliver")}</p>
        {deliveryPeriod?.map((dPeriod, index) => {
          const { period, id } = dPeriod;
          return (
            <label className="labelRadio" key={index}>
              <span>{period}</span>
              <input
                type="radio"
                onChange={(e) =>
                  changeFilter("delivery_period", String(id), true)
                }
                checked={filters?.delivery_period?.includes(String(id))}
                name="delivery_period"
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
      <div className="step">
        <p className="title">{t("filter.company")}</p>

        {manufacturer?.map((manufacture, index) => {
          const { name, id } = manufacture;
          return (
            <label className="labelRadio2" key={index}>
              <span>{name}</span>
              <input
                type="checkbox"
                onChange={(e) =>
                  changeFilter("manufacturer", String(id), false)
                }
                checked={filters?.manufacturer?.includes(String(id))}
                name="manufacturer"
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
      {/* <div className='step'>
        <p className='title'>Способы оплаты</p>
        <label className='labelRadio2'>
          <span>Payme</span>
          <input type='checkbox' />
          <span className='checkmark'></span>
        </label>
        <label className='labelRadio2'>
          <span>Click</span>
          <input type='checkbox' />
          <span className='checkmark'></span>
        </label>
        <label className='labelRadio2'>
          <span>Наличные</span>
          <input type='checkbox' />
          <span className='checkmark'></span>
        </label>
        <label className='labelRadio2'>
          <span>Apelsin</span>
          <input type='checkbox' />
          <span className='checkmark'></span>
        </label>
      </div> */}
      <button
        className="appbtn2"
        onClick={() => {
          clearFilter();
          window.scrollTo(0, 0);
        }}
      >
        {t("filter.clear")}
      </button>
    </Main>
  );
};

export default Filter;
