import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Star from "../../images/svg/Star.svg";
import {
  Breadcrumb,
  FourColumnCard,
  FourColumnContainer,
} from "../../components/GlobalStyledComponents";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const SubCard = styled.div`
  border: 1px solid #e2e6e9;
  border-radius: 18px;
  padding: 28px;
  .starmain {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    span {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      color: #01304f;
      margin-right: 8px;
    }
  }
  .text1 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #01304f;
  }
  .text2 {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #01304f;
    margin-top: 24px;
    margin-bottom: 10px;
  }
  .text3 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #01304f;
    p {
      margin-bottom: 0 !important;
    }
  }
`;
const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
`;

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [preLoading, setPreLoading] = useState(false);
  const [nextUrlReviews, setNextUrlReviews] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = (
    page = 1,
    next_url = `api/v1/review/?page=${page}&page_size=8`
  ) => {
    if (next_url) {
      if (page === 1) {
        setPreLoading(true);
      }
      GetNotAuthInstance()
        .get(next_url)
        .then((res) => {
          if (res.status === 200) {
            const result =
              page === 1
                ? res?.data?.results
                : [...reviews, ...res?.data?.results];
            if (Array.isArray(result)) {
              setReviews(result);
              setNextUrlReviews(res?.data?.next);
            } else {
              setReviews([]);
            }
          }
        })
        .catch((err) => {
          setReviews([]);
        })
        .finally(() => setPreLoading(false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Breadcrumb>
        <ul className="containerr">
          <li>
            <Link to="/">{t("breadcrumb.main")}</Link>
          </li>
          <li>
            <span className="line"> / </span>
          </li>
          <li>
            <span className="disable"> {t("breadcrumb.revieww")}</span>
          </li>
        </ul>
      </Breadcrumb>
      <TopSide className="containerr">
        <p>{t("breadcrumb.revieww")}</p>
      </TopSide>
      <FourColumnContainer>
        {reviews?.length > 0 ? (
          <>
            {reviews?.map((r, index) => {
              const { content, fullname, review } = r;
              return (
                <FourColumnCard key={index}>
                  <SubCard>
                    <div className="starmain">
                      {review === 1 ? (
                        <>
                          <span>1.0</span>
                          <img src={Star} style={{ margin: "0 2px" }} alt="" />
                        </>
                      ) : review === 2 ? (
                        <>
                          <span>2.0</span>
                          {[1, 2].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 3 ? (
                        <>
                          <span>3.0</span>
                          {[1, 2, 3].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 4 ? (
                        <>
                          <span>4.0</span>
                          {[1, 2, 3, 4].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 5 ? (
                        <>
                          <span>5.0</span>
                          {[1, 2, 3, 4, 5].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                    {fullname ? (
                      <p className="text1">{fullname}</p>
                    ) : (
                      <p className="text1">{t("breadcrumb.anonim_user")}</p>
                    )}

                    {/* <p className='text2'>Спасибо!</p> */}
                    <span
                      className="text3"
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                    />
                  </SubCard>
                </FourColumnCard>
              );
            })}
          </>
        ) : null}

        <ShowMore>
          {preLoading ? (
            <>loading</>
          ) : (
            <>
              {nextUrlReviews ? (
                <button
                  className="appbtn"
                  onClick={() => getReviews(2, nextUrlReviews)}
                >
                  {t("breadcrumb.show_more")}
                </button>
              ) : (
                ""
              )}
            </>
          )}
        </ShowMore>
      </FourColumnContainer>
    </>
  );
};

export default Reviews;
