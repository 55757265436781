import React from 'react';
import { Mainn } from '../components/GlobalStyledComponents';
import Footer from './Footer';
import Header from './Header';
import Navigation from './Navigation';

const UserSide = (props) => {
  return (
    <>
      <Mainn>
        <Header />
        <div style={{ marginTop: '30px' }}>{props.children}</div>
        <Footer />
      </Mainn>
    </>
  );
};

export default UserSide;
