import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  login,
  setBasket,
  setConverterVal,
  setFavourite,
  setLan,
  setSumConverter,
  setUser,
  updateScroll,
} from "../redux";
import BasketHeart from "../images/svg/basketheart.svg";
import Baskett from "../images/svg/baskett.svg";
import { getLanguage } from "../helpers/language";
import Globs from "../images/svg/globs.svg";
import LogoAma from "../images/svg/logoama.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { GetAuthInstance, GetNotAuthInstance } from "../helpers/httpClient";
import { getLocalStorage } from "../helpers/localStorage";
import { GoogleLogout } from "react-google-login";
import SearchContent from "../components/Header/SearchContent";
import { HashLink } from "react-router-hash-link";

const ContentTopSub = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .left {
    position: relative;
    z-index: 100;
    ul {
      display: flex;
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        padding: 40px 0;
        margin-right: 36px;

        @media only screen and (max-width: 1150px) and (min-width: 989px) {
          margin-right: 16px;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          padding: 40px 0;
          position: relative;
          width: 0;
          font-family: "Rubik-Regular", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #01304f;
          &:hover::after {
            transform: scaleX(1);
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #1f84c5;
            transform: scaleX(0);
            transition: 400ms;
          }
        }
        .activeLink {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .sub2 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
    }
  }
  .right {
    position: relative;
    z-index: 100;

    .sub3 {
      display: flex;
      align-items: center;
      .btn-secondary {
        margin: 0;
        font-family: "Rubik-Regular", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #01304f;
        background: none;
        border: none;
        text-transform: capitalize;
        &:hover {
          color: #01304f;
          background: none;
          border: none;
        }
      }
      .dropdown-menu {
        border-radius: 8px !important;
        /* transform: translate3d(0px, 75px, 0px) !important; */
        padding: 0;
        border-radius: 0;
        text-align: center;
        min-width: 60px;
      }
      .dropdown-item {
        color: #01304f;
        &:hover,
        &:focus,
        &:active {
          background: #f6f6f6 !important;
        }
      }
    }
  }
`;

const LinkTo = styled.ul`
  a {
    padding: 10px !important;
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Rubik-Regular", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #01304f;
  }
`;
const Nav = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  list-style-type: none;
  & li {
    &:nth-child(1) {
      margin-right: 24px;
    }
    a {
      display: flex;
      align-items: center;
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #01304f;
      position: relative;
      img {
        margin-right: 16px;
      }
      .count {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        font-family: "Rubik-Regular", sans-serif;
        font-size: 10px;
        color: #fff;
        width: 18px;
        height: 18px;
        p {
          display: flex;
          justify-content: center;
          width: 100%;
          transform: translate(-0.5px, 0);
        }
        &::after {
          content: "";
          position: absolute;
          top: 1px;
          left: 1px;
          width: 16px;
          height: 16px;
          background: #ea4335;
          border-radius: 50%;
          z-index: -1;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;

          width: 18px;
          height: 18px;
          background: #fff;
          border-radius: 50%;
          z-index: -2;
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
  & .header-right-link {
    align-items: center;
    display: flex;
    white-space: nowrap;
    & .favorites-basket {
      margin: 0 10px 0 0;
      @media (max-width: 991px) {
        margin: 0;
      }
    }
    & .header-nav-word {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
`;

const clientId =
  "508835498411-l0o66kd5v7obllci229gffn7eh6c2ouh.apps.googleusercontent.com";

const Sub3Content = (props) => {
  const {
    tokenCheck,
    isOpenUser,
    toggleIsOpenUser,
    logOut,
    user,
    clientId,
    t,
    showItem,
  } = props;

  return (
    <>
      {!tokenCheck ? (
        <Link to="/login" className="appbtn3">
          {t("navbar.login")}
        </Link>
      ) : (
        <Dropdown
          isOpen={isOpenUser}
          toggle={toggleIsOpenUser}
          className="userDropDown"
        >
          <DropdownToggle>
            {user.first_name != "" ? user.first_name : t("user_info.unknown")}{" "}
            {user.last_name != "" ? user.last_name : t("user_info.unknown")}
          </DropdownToggle>
          <DropdownMenu className="">
            <DropdownItem>
              <LinkTo>
                <Link to="/purchase-history">
                  {t("breadcrumb.history_purchase")}
                </Link>
              </LinkTo>
            </DropdownItem>
            <DropdownItem>
              <LinkTo>
                <Link to="/profile">Профиль</Link>
              </LinkTo>
            </DropdownItem>
            <DropdownItem className="itemm" onClick={logOut}>
              <React.Fragment>
                <GoogleLogout
                  clientId={clientId}
                  // onLogoutSuccess={(e) => console.log(e)}
                  // onFailure={(e) => console.log(e)}
                  buttonText={t("navbar.login_out")}
                ></GoogleLogout>
              </React.Fragment>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

const MoneyLen = (props) => {
  const {
    isOpenMoney,
    toggleIsOpenMoney,
    isOpenLen,
    toggleIsOpenLen,
    moneyChange,
    onHandleMoney,
    lan,
    onLanguageHandle,
    t,
    showItem,
  } = props;
  return (
    <>
      <Dropdown isOpen={isOpenMoney} toggle={toggleIsOpenMoney}>
        <DropdownToggle>
          {moneyChange === "EUR" ? (
            <>EUR</>
          ) : moneyChange === "KZT" ? (
            <>KZT</>
          ) : moneyChange === "UZS" ? (
            <>UZS</>
          ) : (
            <>EUR</>
          )}
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: "translate(4px, -1px)" }}
          >
            <path
              d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5"
              stroke="#6F818F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </DropdownToggle>
        <DropdownMenu className="">
          <DropdownItem onClick={() => onHandleMoney("EUR")}>EUR</DropdownItem>
          <DropdownItem onClick={() => onHandleMoney("KZT")}>KZT</DropdownItem>
          <DropdownItem onClick={() => onHandleMoney("UZS")}>UZS</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown isOpen={isOpenLen} toggle={toggleIsOpenLen}>
        <DropdownToggle>
          <img
            src={Globs}
            style={{ transform: "translate(-4px, -2px)" }}
            alt=""
          />
          {lan === "uz" ? (
            <>{t("navbar.uz")}</>
          ) : lan === "ru" ? (
            <>{t("navbar.ru")}</>
          ) : (
            <>{t("navbar.en")}</>
          )}
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: "translate(4px, -1px)" }}
          >
            <path
              d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5"
              stroke="#6F818F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </DropdownToggle>
        <DropdownMenu className="">
          <DropdownItem onClick={() => onLanguageHandle("uz")}>
            {t("navbar.uz")}
          </DropdownItem>
          <DropdownItem onClick={() => onLanguageHandle("ru")}>
            {t("navbar.ru")}
          </DropdownItem>
          <DropdownItem onClick={() => onLanguageHandle("en")}>
            {t("navbar.en")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const Header = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [showItem, setShowItem] = useState(false);
  const [getCatList, setGetCatList] = useState([]);
  const [getConverterVal, setGetConverterVal] = useState("1");
  const [isOpenLen, setIsOpenLen] = useState(false);
  const [isOpenMoney, setIsOpenMoney] = useState(false);
  const [isOpenLen2, setIsOpenLen2] = useState(false);
  const [isOpenMoney2, setIsOpenMoney2] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [showItem2, setShowItem2] = useState(false);
  const [closeMenu, setCloseMenu] = useState(false);

  const { basket, scroll, converterVal, user, favourite } = useSelector(
    (state) => state
  );
  const { t, i18n } = useTranslation();

  const lan = getLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleIsOpenLen = () => setIsOpenLen(!isOpenLen);

  const toggleIsOpenMoney = () => setIsOpenMoney(!isOpenMoney);

  const toggleIsOpenLen2 = () => setIsOpenLen2(!isOpenLen2);

  const toggleIsOpenMoney2 = () => setIsOpenMoney2(!isOpenMoney2);

  const toggleIsOpenCategory = () => setIsOpenCategory(!isOpenCategory);

  const toggleIsOpenUser = () => setIsOpenUser(!isOpenUser);

  const handleCloseMenu = (i) => setCloseMenu(i);

  const onLanguageHandle = (newLang) => {
    i18n.changeLanguage(newLang);
    dispatch(setLan(newLang));
    window.localStorage.setItem("language", newLang);
  };

  let moneyChange = window.localStorage.getItem("money");
  const onHandleMoney = (e) => {
    dispatch(setConverterVal(e));
    window.localStorage.setItem("money", e);

    handleCloseMenu(false);

    e == "EUR"
      ? getConverter("api/v1/converter?to=EUR&from=EUR")
      : e == "KZT"
      ? getConverter("api/v1/converter?to=KZT&from=EUR")
      : e == "UZS"
      ? getConverter("api/v1/converter?to=UZS&from=EUR")
      : getConverter("api/v1/converter?to=EUR&from=EUR");
  };

  const getConverter = (
    url = `api/v1/converter?to=${moneyChange ? moneyChange : "EUR"}&from=EUR`
  ) => {
    GetNotAuthInstance()
      .get(url)
      .then((res) => {
        const val = res?.data?.rate ?? 1;
        window.localStorage.setItem("sumConverter", val);
        setGetConverterVal(val);
        dispatch(setSumConverter(val));
      })
      .catch((error) => {
        setGetConverterVal("");
      });
  };

  const showItems = () => {
    if (window.innerWidth > 992) {
      setShowItem(true);
    } else {
      setShowItem(false);
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 60 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  const getCategories = () => {
    GetNotAuthInstance()
      .get(`api/v1/category/`)
      .then((res) => {
        if (res.status === 200) {
          setGetCatList(res.data);
        }
      })
      .catch((error) => {
        setGetCatList([]);
      });
  };

  const logOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userInfo");
    dispatch(login(false));
    window.location.reload();
  };

  const tokenCheck = localStorage.getItem("token");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    getCategories();
    getConverter();
    showItems();
  }, []);

  useEffect(() => {
    let position = window.pageYOffset;
    window.addEventListener("scroll", () => {
      const currentPosition = window.pageYOffset;
      if (position < currentPosition) {
        dispatch(updateScroll(true));
      } else {
        dispatch(updateScroll(false));
      }
      position = currentPosition;
    });
  }, []);

  useEffect(() => {
    const { basket } = getLocalStorage(["basket"]);
    const toNewBasket = JSON.parse(basket);
    dispatch(setBasket(Array.isArray(toNewBasket) ? toNewBasket : []));

    const { favourite } = getLocalStorage(["favourite"]);
    const toNewFavourite = JSON.parse(favourite);
    dispatch(setFavourite(Array.isArray(toNewFavourite) ? toNewFavourite : []));
  }, []);

  useEffect(() => {
    const userInfo = window.localStorage.getItem("userInfo");
    const url = `api/user/user/${userInfo}/`;
    if (userInfo) {
      GetAuthInstance()
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data;
            dispatch(setUser(result));
          }
        })
        .catch((e) => {})
        .finally(() => {});
    }
  }, []);

  window.addEventListener("resize", showItems);

  return (
    <>
      <div
        className="content_top"
        style={showItem ? { height: "105px" } : { height: "60px" }}
      >
        <div className="containerr">
          <ContentTopSub>
            {!showItem ? (
              <span
                onClick={handleCloseMenu}
                style={{
                  color: "#1D4A8E",
                  fontSize: "25px",
                  cursor: "pointer",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                &#9776;
              </span>
            ) : null}
            <div
              className="left"
              style={!showItem ? { display: "none" } : null}
            >
              <ul>
                <li>
                  <Link
                    to="/"
                    className={location?.pathname === "/" ? "activeLink" : ""}
                  >
                    {t("navbar.link1")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    className={
                      location?.pathname === "/about-us" ? "activeLink" : ""
                    }
                  >
                    {t("navbar.link2")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contacts"
                    className={
                      location?.pathname === "/contacts" ? "activeLink" : ""
                    }
                  >
                    {t("navbar.link4")}
                  </Link>
                </li>
                <li>
                  <HashLink to="/#news">{t("navbar.link5")}</HashLink>
                </li>
              </ul>
            </div>
            <div className="sub2">
              <Link to="/">
                <img
                  src={LogoAma}
                  style={!showItem ? { display: "none" } : null}
                  alt=""
                />
              </Link>
            </div>
            <div
              className="closeMenuMainBack"
              onClick={() => handleCloseMenu(false)}
              data-active={closeMenu ? "active" : "inactive"}
            />
            <div
              className="closeMenuMain"
              data-active={closeMenu ? "active" : "inactive"}
            >
              <div className="svgMain">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  onClick={() => handleCloseMenu(false)}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    fill="#000"
                  />
                </svg>
              </div>
              <div className="right">
                <div className="sub3">
                  <MoneyLen
                    isOpenMoney={isOpenMoney}
                    toggleIsOpenMoney={toggleIsOpenMoney}
                    isOpenLen={isOpenLen}
                    toggleIsOpenLen={toggleIsOpenLen}
                    moneyChange={moneyChange}
                    onHandleMoney={onHandleMoney}
                    lan={lan}
                    onLanguageHandle={onLanguageHandle}
                    t={t}
                  />
                </div>
              </div>
              <ul className="ulList">
                <li>
                  <Link to="/" onClick={() => handleCloseMenu(false)}>
                    {t("navbar.link1")}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" onClick={() => handleCloseMenu(false)}>
                    {t("navbar.link2")}
                  </Link>
                </li>
                <li>
                  <Link to="/contacts" onClick={() => handleCloseMenu(false)}>
                    {t("navbar.link4")}
                  </Link>
                </li>
                <li>
                  <HashLink to="/#news" onClick={() => handleCloseMenu(false)}>
                    {t("navbar.link5")}
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="right" style={showItem ? {} : { padding: "5px 0" }}>
              <div
                className="sub3"
                style={
                  !showItem
                    ? { flexDirection: "row-reverse", padding: "5px 0" }
                    : null
                }
              >
                {showItem ? (
                  <MoneyLen
                    isOpenMoney={isOpenMoney2}
                    toggleIsOpenMoney={toggleIsOpenMoney2}
                    isOpenLen={isOpenLen2}
                    toggleIsOpenLen={toggleIsOpenLen2}
                    moneyChange={moneyChange}
                    onHandleMoney={onHandleMoney}
                    lan={lan}
                    onLanguageHandle={onLanguageHandle}
                    t={t}
                  />
                ) : null}
                <Sub3Content
                  isOpenMoney={isOpenMoney}
                  toggleIsOpenMoney={toggleIsOpenMoney}
                  isOpenLen={isOpenLen}
                  toggleIsOpenLen={toggleIsOpenLen}
                  moneyChange={moneyChange}
                  onHandleMoney={onHandleMoney}
                  tokenCheck={tokenCheck}
                  lan={lan}
                  onLanguageHandle={onLanguageHandle}
                  isOpenUser={isOpenUser}
                  toggleIsOpenUser={toggleIsOpenUser}
                  logOut={logOut}
                  user={user}
                  clientId={clientId}
                  showItem={showItem}
                  t={t}
                />
              </div>
            </div>
          </ContentTopSub>
        </div>
      </div>
      <div className={`navbarr ${stickyClass}`}>
        <div className="containerr bg-default">
          <div className="left">
            {showItem ? (
              <Dropdown
                isOpen={isOpenCategory}
                toggle={toggleIsOpenCategory}
                className="appbtn4"
              >
                <DropdownToggle>
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "12px",
                      transform: "translate(0, -1px)",
                    }}
                  >
                    <rect y="0.5" width="20" height="2" rx="1" fill="white" />
                    <rect y="6.5" width="20" height="2" rx="1" fill="white" />
                    <rect y="12.5" width="20" height="2" rx="1" fill="white" />
                  </svg>
                  {t("navbar.sublink1")}
                </DropdownToggle>
                <DropdownMenu className="">
                  {getCatList.map((cat, index) => {
                    return (
                      <DropdownItem key={index}>
                        <Link to={`/catalog/${cat.slug}`}>{cat.name}</Link>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {!showItem ? (
              <Link to="/">
                <img
                  src={LogoAma}
                  style={
                    !showItem ? { width: "70px", marginRight: "24px" } : null
                  }
                  alt=""
                />
              </Link>
            ) : null}
            <form style={!showItem ? { width: "100%" } : {}}>
              <div className="inputcard">
                <SearchContent showItem={showItem} />
              </div>
            </form>
          </div>

          <Nav>
            <li
              className="f400 f16"
              style={!showItem ? { marginRight: "0" } : null}
            >
              <Link to="/featured">
                <img src={BasketHeart} alt="" />
                {favourite.length ? (
                  <div className="count">
                    <p>{favourite.length}</p>
                  </div>
                ) : null}
                {showItem ? <span>{t("navbar.sublink3")}</span> : null}
              </Link>
            </li>
            <li
              className="f400 f16"
              style={!showItem ? { marginRight: "0" } : null}
            >
              <Link to="/basket">
                <img
                  src={Baskett}
                  style={!showItem ? { marginRight: "0" } : null}
                  alt=""
                />
                {basket.length ? (
                  <div className="count">
                    <p>{basket.length}</p>
                  </div>
                ) : null}

                {showItem ? <span>{t("navbar.sublink4")}</span> : null}
              </Link>
            </li>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default Header;
