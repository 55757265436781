import React from "react";
import styled from "styled-components";

const Main = styled.div`
  .loading {
    height: 0;
    width: 0;
    padding: 6px;
    border: 3px solid #fff;
    border-right-color: #888;
    border-radius: 22px;
    animation: rotate 1s infinite linear;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => {
  return (
    <Main>
      <div className="loading"></div>
    </Main>
  );
};

export default Loading;
