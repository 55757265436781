import React, { useEffect, useState } from "react";
import { FourColumnCard, FourColumnContainer } from "../GlobalStyledComponents";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { GetNotAuthInstance } from "../../helpers/httpClient";

const TopSide = styled.div`
  margin-top: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    &:nth-child(1) {
      font-family: "Rubik-SemiBold", sans-serif;
      font-size: 32px;
      line-height: 38px;
      color: #01304f;
      @media only screen and (max-width: 776px) and (min-width: 320px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    &:nth-child(2) {
      font-family: "Rubik-Medium", sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #1d4a8e;
    }
  }
`;
const Info = styled.div`
  .text1 {
    margin-top: 15px;
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #01304f;
  }
  .text2 {
    margin-top: 10px;
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 150%;
    color: #6f818f;
  }
  .img_main {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #f4f9fc;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const OurAdvantages = () => {
  const [list, setList] = useState([]);
  const { t, i18n } = useTranslation();

  const getAdventures = () => {
    GetNotAuthInstance()
      .get(`api/v1/our-advantages`)
      .then((res) => {
        if (res.status === 200) {
          setList(res.data);
        }
      })
      .catch((error) => {
        setList([]);
      });
  };

  useEffect(() => {
    getAdventures();
  }, [i18n.language]);

  return (
    <>
      <TopSide className="containerr">
        <p>{t("our_advantages.title")}</p>
      </TopSide>
      <FourColumnContainer>
        {list?.map((item, index) => (
          <FourColumnCard key={index}>
            <Info>
              <div className="img_main">
                <img src={item?.image} alt="" />
              </div>
              <p className="text1">{item?.title}</p>
              <p className="text2">{item?.body}</p>
            </Info>
          </FourColumnCard>
        ))}
      </FourColumnContainer>
    </>
  );
};

export default OurAdvantages;
