import styled from "styled-components";

export const FourColumnContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const FourColumnCard = styled.div`
  position: relative;
  margin: 12px;
  width: calc((100% / 4) - 24px);
  transition: 400ms;

  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }
`;

export const ThreeColumnContainer2 = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const ThreeColumnCard2 = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 3) - 30px);
  min-height: 100px;
  transition: 400ms;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 1px 6px 5px 1px rgba(115, 141, 163, 0.1);
    border-radius: 16px;
  }
  .product_main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 130px;
    img {
      width: auto;
      height: 100%;
    }
  }
  .fav {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;
  }
  .info {
    padding: 0 16px 16px 16px;
    .appbtn,
    .appbtn2 {
      width: 100%;
      margin-top: 15px;
    }
    p {
      font-family: "Rubik-Regular", sans-serif;
      &:nth-child(1) {
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #6f818f;
      }
      &:nth-child(2) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        min-height: 48px;
      }
      &:nth-child(3) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        span {
          font-family: "Rubik-SemiBold", sans-serif;
        }
      }
    }
  }
`;
export const ThreeColumnContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const ThreeColumnCard = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 3) - 30px);
  min-height: 200px;
  transition: 400ms;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }

  /* &:hover {
    box-shadow: 1px 6px 5px 1px rgba(115, 141, 163, 0.1);
    border-radius: 16px;
  } */
  .product_main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 130px;
    img {
      width: auto;
      height: 100%;
    }
  }
  .fav {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;
  }
  .info {
    padding: 0 16px 16px 16px;
    .appbtn,
    .appbtn2 {
      width: 100%;
      margin-top: 15px;
    }
    p {
      font-family: "Rubik-Regular", sans-serif;
      &:nth-child(1) {
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #6f818f;
      }
      &:nth-child(2) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        min-height: 48px;
      }
      &:nth-child(3) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        span {
          font-family: "Rubik-SemiBold", sans-serif;
        }
      }
    }
  }
`;

export const TwoColumnMain = styled.div`
  background: #ffffff;
  border: 1px solid #e2e6e9;
  border-radius: 16px;
  margin-top: 24px;
  &:nth-last-child(1) {
    margin-bottom: 100px;
  }
`;
export const TwoColumnTop = styled.div`
  padding: 0 24px;
  .header {
    padding: 24px 0;
    border-bottom: 1px solid #e2e6e9;
    .sub {
      margin-bottom: 16px;
      p {
        &:nth-child(1) {
          font-family: "Rubik-Medium", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #01304f;
          @media only screen and (max-width: 768px) and (min-width: 320px) {
            margin-bottom: 16px;
          }
        }
        &:nth-child(2) {
          font-family: "Rubik-Medium", sans-serif;
          font-size: 24px;
          line-height: 28px;
          color: #01304f;
        }
      }
    }
    .sub2 {
      p {
        font-family: "Rubik-Regular", sans-serif;
        font-size: 16px;
        line-height: 19px;
        &:nth-child(1) {
          color: #6f818f;
          @media only screen and (max-width: 768px) and (min-width: 320px) {
            margin-bottom: 16px;
          }
        }
        &:nth-child(2) {
          @media only screen and (max-width: 768px) and (min-width: 320px) {
            margin-left: 16px;
          }
          .orange {
            color: #fbbc05;
            &::after {
              background: #fbbc05;
            }
          }
          .green {
            color: #5ebd50;
            &::after {
              background: #5ebd50;
            }
          }
          .red {
            color: #ea4335;
            &::after {
              background: #ea4335;
            }
          }
          span {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              left: -15px;
              top: 7px;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .sub,
    .sub2 {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) and (min-width: 320px) {
        flex-direction: column;
      }
    }
  }
`;
export const TwoColumnContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  .top {
    border-bottom: 1px solid #e2e6e9;
  }
`;

export const TwoColumnCard = styled.div`
  position: relative;
  margin: 16px 24px;
  width: calc((100% / 2) - 50px);
  transition: 400ms;
  @media (max-width: 768px) and (min-width: 576px) {
    width: 100%;
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }
  display: flex;
  align-items: center;
  .productimg {
    width: 80px;
    height: 80px;
  }
  & > div {
    margin-left: 16px;
  }
  p {
    font-family: "Rubik-Regular", sans-serif;
    &:nth-child(1) {
      font-size: 16px;
      line-height: 19px;
      color: #01304f;
      margin-bottom: 8px;
    }
    &:nth-child(2) {
      font-size: 14px;
      line-height: 17px;
      color: #6f818f;
    }
  }
`;

export const ProductContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const ProductCard = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 4) - 30px);
  transition: 400ms;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 1px 6px 5px 1px rgba(115, 141, 163, 0.1);
    border-radius: 16px;
  }
  .product_main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 130px;
    img {
      width: auto;
      height: 100%;
    }
  }
  .fav {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;
  }
  .info {
    padding: 0 16px 16px 16px;
    .appbtn,
    .appbtn2 {
      width: 100%;
      margin-top: 15px;
    }
    p {
      font-family: "Rubik-Regular", sans-serif;
      &:nth-child(1) {
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #6f818f;
      }
      &:nth-child(2) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        min-height: 48px;
      }
      &:nth-child(3) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 150%;
        color: #01304f;
        span {
          font-family: "Rubik-SemiBold", sans-serif;
        }
      }
    }
  }
`;

export const Breadcrumb = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 19px;
      padding: 3px;
      a {
        color: #01304f;
      }
      .disable,
      .line {
        color: #6f818f;
      }
      .line {
        margin: 0 5px;
      }
    }
  }
`;

export const Mainn = styled.div`
  .navbarr {
    margin-top: -2px;
    position: relative;
    z-index: 90;
    width: 100%;
    background: rgb(246, 246, 246);
    padding: 24px 0;
    .appbtn4 {
      button {
        padding: 16.5px 0;
        background: #1d4a8e;
        border-radius: 8px;
        border: none;
        font-family: "Rubik-Medium", sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        &:hover {
          color: #fff;
          background: #1d4a8e;
          border: none;
        }
      }

      .dropdown-menu {
        border-radius: 8px !important;
        transform: translate3d(0px, 58px, 0px) !important;
        padding: 0;
        border-radius: 0;
        text-align: left;
        button {
          padding: 0;
        }
        &.show {
          display: block;
          min-height: 45px;
          max-height: 378px;
          overflow-y: auto;
        }
      }
      .dropdown-item {
        background: transparent;
        margin-right: 0 !important;
        border-radius: 0 !important;
        min-width: 280px !important;
        a {
          font-family: "Rubik-Regular", sans-serif;
          color: #01304f;
          padding: 12px;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #000;
          &:nth-last-child(1) {
            border-bottom: 1px solid transparent;
          }
        }
        &:hover,
        &:focus,
        &:active {
          background: #f6f6f6 !important;
          a {
            color: #01304f;
          }
        }
      }
    }
    .bg-default {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        width: 100%;
        margin-right: 24px;
        align-items: center;
        button {
          width: 176px;
          margin-right: 24px;
        }
        form {
          width: calc(100% - 176px);
          .inputcard {
            background: #ffffff;
            border: 1px solid #e2e6e9;
            border-radius: 8px;
            display: flex;
            input {
              width: 100%;
              border: none;
              font-size: 16px;
              line-height: 19px;
              &:focus {
                outline: none;
              }
              &::placeholder {
                font-size: 16px;
                line-height: 19px;
                color: #6f818f;
              }
            }
            img {
              cursor: pointer;
              padding: 0 16px;
            }
            button {
              padding: 0;
              margin: 0;
              border: none;
              width: 70px;
              background: transparent;
            }
          }
        }
      }
    }
  }

  .sticky-nav {
    position: sticky;
    top: -2px;
    left: 0;
  }
`;
