import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { PageNFound } from "../components/NotFound";
import { UserSide } from "../layout";
import Login from "./Authorization/Login";
import Profile from "./Authorization/Profile";
import {
  AboutUs,
  Contacts,
  Featured,
  Home,
  Reviews,
  PurchaseHistory,
  Catalog,
  Basket,
  CatalogSingle,
} from "./Public";
import NewsSingle from "./Public/NewsSingle";

const Pages = () => (
  <Routes>
    <Route path="*" element={<PageNFound />} />
    <Route path="/login" element={<Login />} />

    {/* --------------client side route-------------- */}
    <Route
      element={
        <UserSide>
          <Outlet />
        </UserSide>
      }
    >
      <Route path="/">
        <Route index={true} element={<Home />} />
        <Route index={false} path="profile" element={<Profile />} />
        <Route index={false} path="about-us" element={<AboutUs />} />
        <Route index={false} path="contacts" element={<Contacts />} />
        <Route index={false} path="reviews" element={<Reviews />} />
        <Route index={false} path="featured" element={<Featured />} />
        <Route index={false} path="catalog" element={<Catalog />} />
        <Route path="/catalog">
          <Route index={true} element={<Catalog />} />
          <Route index={false} path=":slugg" element={<Catalog />} />
        </Route>
        <Route path="/catalog-single">
          <Route index={false} path=":slug" element={<CatalogSingle />} />
        </Route>
        <Route index={false} path="basket" element={<Basket />} />
        <Route
          index={false}
          path="purchase-history"
          element={<PurchaseHistory />}
        />
        <Route path="/news">
          <Route index={false} path=":id" element={<NewsSingle />} />
        </Route>
      </Route>
    </Route>
    {/* --------------client side route-------------- */}
  </Routes>
);
export default Pages;
