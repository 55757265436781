import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Breadcrumb } from "../../components/GlobalStyledComponents";
import Bag from "../../images/svg/Bag.svg";
import Loved from "../../images/svg/Loved.svg";
import Loved2 from "../../images/svg/Loved2.svg";
import ProdImg from "../../images/prodimg.png";
import { Single } from "../../components/Catalog";
import { Link, useParams } from "react-router-dom";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBasket, setFavourite } from "../../redux";
import { setLocalStorage } from "../../helpers/localStorage";

const ProdInfo = styled.div`
  padding-top: 55px;
  padding-bottom: 58px;
  border-bottom: 1px solid #e2e6e9;
  margin-bottom: 50px;
  .appbtn,
  .appbtn2 {
    min-width: 223px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .sub_flex {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 992px) and (min-width: 320px) {
      flex-direction: column;
    }
    .left {
      width: 50%;
      padding-right: 123px;

      @media only screen and (max-width: 992px) and (min-width: 320px) {
        width: 100%;
        padding-right: 0;
      }
      img {
        width: 100%;
        object-fit: contain;

        @media only screen and (max-width: 992px) and (min-width: 320px) {
          height: 300px;
        }
      }
    }
    .right {
      width: 50%;

      @media only screen and (max-width: 992px) and (min-width: 320px) {
        width: 100%;
      }
      p {
        margin: 0;
        &:nth-child(1) {
          font-family: "Rubik-SemiBold", sans-serif;
          font-size: 24px;
          line-height: 150%;
          color: #01304f;
          margin-bottom: 16px;
        }
        &:nth-child(2) {
          font-family: "Rubik-Regular", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #6f818f;
        }
        &:nth-child(3) {
          margin-top: 36px;
          margin-bottom: 16px;
          font-family: "Rubik-Medium", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #01304f;
        }
        &:nth-child(4) {
          p {
            word-wrap: break-word;
            margin-bottom: 68px;
            font-family: "Rubik-Regular", sans-serif;
            font-size: 16px;
            line-height: 150%;
            color: #01304f;
          }
        }
        &:nth-child(5) {
          margin-bottom: 24px;
          font-family: "Rubik-Medium", sans-serif;
          font-size: 32px;
          line-height: 38px;
          color: #01304f;
        }
      }
    }
  }
  .btngroup {
    display: flex;
    align-items: center;
    & > div {
      &:nth-child(1) {
        margin-right: 24px;
      }
    }
  }
`;

const ProdDesc = styled.div`
  p {
    margin: 0;
  }
  .title {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #01304f;
    margin-bottom: 16px;
  }
  .text {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 150%;
    color: #6f818f;
    margin-bottom: 36px;
    &:nth-last-child(1) {
      margin-bottom: 80px;
    }
  }
`;

const AddFav = styled.div`
  position: relative;
  & .add-favorites {
    border-radius: 50%;
    height: 36px;
    right: 18px;
    top: 16px;
    width: 36px;
    & svg {
      transition: 0.3s;
    }
    & .fixed-svg {
      left: 9px;
      position: absolute;
      top: 11px;
    }
    & .like {
      transform: scale(0);
    }
    &:active {
      & svg {
        transform: scale(1.3);
      }
    }
  }
  & .liked {
    background: #ea4335;
  }
`;

const Catalog = () => {
  const [product, setProduct] = useState({});
  const [recentViewed, setRecentViewed] = useState([]);
  const dispatch = useDispatch();
  const { basket, favourite } = useSelector((state) => state);

  const { t, i18n } = useTranslation();
  const { slug } = useParams();

  let moneyChange = window.localStorage.getItem("money");
  let sumConverter = window.localStorage.getItem("sumConverter");

  useEffect(() => {
    GetNotAuthInstance()
      .get(`api/v1/product/${slug}`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data;
          if (result) {
            setProduct(result);
            addToRecent(result?.id);
          } else {
            setProduct({});
          }
        }
      })
      .catch((error) => {
        setProduct({});
      });
  }, [i18n.language]);

  const addToRecent = (product__id = 0) => {
    let recent = window.localStorage.getItem("recent");
    recent = JSON.parse(recent?.length ? recent : "[]");
    setRecentViewed(recent);
    if (!recent.includes(product__id)) recent = [product__id, ...recent];
    if (recent.length > 4) recent = recent.splice(0, 4);
    window.localStorage.setItem("recent", JSON.stringify(recent));
  };

  const id = product?.id;
  const isAddedToFavourite = favourite.some((d) => d.id === id);
  const isAddedToBasket = basket.some((d) => d.id === id);

  const changeFavourite = () => {
    const toNewFavourite = isAddedToFavourite
      ? favourite.filter((d) => d.id !== id)
      : [...favourite, { id, quantity: 1 }];
    dispatch(setFavourite(toNewFavourite));
    const storage = [
      { name: "favourite", value: JSON.stringify(toNewFavourite) },
    ];
    setLocalStorage(storage);
  };

  const changeBasket = () => {
    const toNewBasket = isAddedToBasket
      ? basket.filter((d) => d.id !== id)
      : [...basket, { id, quantity: 1 }];
    dispatch(setBasket(toNewBasket));
    const storage = [{ name: "basket", value: JSON.stringify(toNewBasket) }];
    setLocalStorage(storage);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Breadcrumb>
        <ul className="containerr">
          <li>
            <Link to="/">{t("breadcrumb.main")}</Link>
          </li>
          <li>
            <span className="line"> / </span>
          </li>
          <li>
            <Link to="/catalog">{t("breadcrumb.no_catalog_product")}</Link>
          </li>
          <li>
            <span className="line"> / </span>
          </li>
          <li>
            <span className="disable">{product?.name}</span>
          </li>
        </ul>
      </Breadcrumb>
      <div className="containerr">
        <ProdInfo>
          <div className="sub_flex">
            <div className="left">
              <img
                src={
                  Array.isArray(product?.images)
                    ? product?.images[0]
                      ? product?.images[0].image
                      : ""
                    : ""
                }
                alt=""
              />
            </div>
            <div className="right">
              <p>{product?.name}</p>
              <p>{product?.manufacturer?.name}</p>
              <p>{t("catalog_single.desc")}</p>
              <p>
                <span
                  className="text1"
                  dangerouslySetInnerHTML={{
                    __html: product?.description,
                  }}
                />
              </p>
              <p>
                {(product?.price * sumConverter).toFixed(0)}
                {moneyChange === "EUR" ? (
                  <> EUR</>
                ) : moneyChange === "KZT" ? (
                  <> KZT</>
                ) : moneyChange === "UZS" ? (
                  <> UZS</>
                ) : (
                  <> EUR</>
                )}
              </p>
              <div className="btngroup">
                <div className="">
                  <button
                    className={isAddedToBasket ? "appbtn2" : "appbtn"}
                    onClick={changeBasket}
                  >
                    {isAddedToBasket ? (
                      t("catalog_single.added")
                    ) : (
                      <span>
                        <img
                          src={Bag}
                          style={{
                            marginRight: "10px",
                            transform: "translate(0,-2px)",
                          }}
                          alt=""
                        />
                        {t("catalog_single.basket")}
                      </span>
                    )}
                  </button>
                </div>
                <AddFav className="">
                  <button
                    onClick={() => changeFavourite()}
                    className={
                      !isAddedToFavourite
                        ? "add-favorites"
                        : "liked add-favorites"
                    }
                  >
                    <svg
                      className={
                        isAddedToFavourite ? "fixed-svg" : "like fixed-svg"
                      }
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.2082 0.0838733C12.734 0.0838733 13.259 0.15804 13.7582 0.32554C16.834 1.32554 17.9423 4.70054 17.0165 7.65054C16.4915 9.15804 15.6332 10.5339 14.509 11.658C12.8999 13.2164 11.134 14.5997 9.23318 15.7914L9.02485 15.9172L8.80818 15.783C6.90068 14.5997 5.12485 13.2164 3.50068 11.6497C2.38402 10.5255 1.52485 9.15804 0.991518 7.65054C0.0498511 4.70054 1.15818 1.32554 4.26735 0.30804C4.50902 0.224707 4.75818 0.166373 5.00818 0.133873H5.10818C5.34235 0.0997066 5.57485 0.0838733 5.80818 0.0838733H5.89985C6.42485 0.0997066 6.93318 0.191373 7.42568 0.358873H7.47485C7.50818 0.374707 7.53318 0.392207 7.54985 0.40804C7.73402 0.467207 7.90818 0.533873 8.07485 0.62554L8.39152 0.767207C8.46804 0.808017 8.55392 0.870374 8.62815 0.924266C8.67518 0.958411 8.71753 0.989158 8.74985 1.00887C8.76345 1.0169 8.77727 1.02496 8.7912 1.0331C8.86265 1.07481 8.93709 1.11826 8.99985 1.16637C9.92568 0.458873 11.0499 0.07554 12.2082 0.0838733ZM14.4248 6.08387C14.7665 6.07471 15.0582 5.80054 15.0832 5.44971V5.35054C15.1082 4.18304 14.4007 3.12554 13.3248 2.71721C12.9832 2.59971 12.6082 2.78387 12.4832 3.13387C12.3665 3.48387 12.5498 3.86721 12.8998 3.99137C13.434 4.19137 13.7915 4.71721 13.7915 5.29971V5.32554C13.7757 5.51637 13.8332 5.70054 13.9498 5.84221C14.0665 5.98387 14.2415 6.06637 14.4248 6.08387Z"
                        fill="white"
                      />
                    </svg>

                    <svg
                      width="21"
                      height="24"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.91466 6.73221C1.19932 4.49888 2.03532 1.94621 4.37999 1.19088C5.61332 0.792881 6.97466 1.02755 7.99999 1.79888C8.96999 1.04888 10.3813 0.795548 11.6133 1.19088C13.958 1.94621 14.7993 4.49888 14.0847 6.73221C12.9713 10.2722 7.99999 12.9989 7.99999 12.9989C7.99999 12.9989 3.06532 10.3135 1.91466 6.73221Z"
                        stroke="#c1cbd2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.6667 3.46667C11.3801 3.69734 11.8841 4.33401 11.9447 5.08134"
                        stroke="#c1cbd2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </AddFav>
              </div>
            </div>
          </div>
        </ProdInfo>
      </div>
      <div className="containerr_small">
        <ProdDesc>
          {product?.properties?.length ? (
            <>
              {product?.properties?.map((p, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className="title">
                      {p?.prop_title
                        ? p?.prop_title[0]
                          ? p?.prop_title[0].name
                          : ""
                        : ""}
                    </p>
                    <p className="text">
                      <span
                        className="text1"
                        dangerouslySetInnerHTML={{
                          __html: p?.value,
                        }}
                      />
                    </p>
                  </React.Fragment>
                );
              })}
            </>
          ) : null}
        </ProdDesc>
      </div>
      {/* <Single /> */}
    </>
  );
};

export default Catalog;
