import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Rate from "rc-rate";
import { FourColumnCard, FourColumnContainer } from "../GlobalStyledComponents";
import { Link } from "react-router-dom";
import Star from "../../images/svg/Star.svg";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { useTranslation } from "react-i18next";

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: 24px;
  }
  .rc-rate-star-full .rc-rate-star-second {
    color: #ea4335;
  }
`;
const TopSide = styled.div`
  margin-top: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: "Rubik-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const SubCard = styled.div`
  border: 1px solid #e2e6e9;
  border-radius: 18px;
  padding: 28px;
  .starmain {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    span {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      color: #01304f;
      margin-right: 8px;
    }
  }
  .text1 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #01304f;
  }
  .text2 {
    font-family: "Rubik-Medium", sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #01304f;
    margin-top: 24px;
    margin-bottom: 10px;
  }
  .text3 {
    font-family: "Rubik-Regular", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #01304f;
    p {
      margin-bottom: 0 !important;
    }
  }
`;

const OurReviews = () => {
  const [reviews, setReviews] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GetNotAuthInstance()
      .get(`api/v1/review/`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setReviews(result);
          } else {
            setReviews([]);
          }
        }
      })
      .catch((error) => {
        setReviews([]);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopSide className="containerr">
        <p>{t("app.header_title3")}</p>
        <Link to="/reviews">{t("app.show_all")}</Link>
      </TopSide>
      <FourColumnContainer>
        {reviews?.length > 0 ? (
          <>
            {reviews?.map((r, index) => {
              const { content, fullname, review, is_home } = r;
              return is_home === true ? (
                <FourColumnCard key={index}>
                  <SubCard>
                    <div className="starmain">
                      {review === 1 ? (
                        <>
                          <span>1.0</span>
                          <img src={Star} style={{ margin: "0 2px" }} alt="" />
                        </>
                      ) : review === 2 ? (
                        <>
                          <span>2.0</span>
                          {[1, 2].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 3 ? (
                        <>
                          <span>3.0</span>
                          {[1, 2, 3].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 4 ? (
                        <>
                          <span>4.0</span>
                          {[1, 2, 3, 4].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : review === 5 ? (
                        <>
                          <span>5.0</span>
                          {[1, 2, 3, 4, 5].map((index) => {
                            return (
                              <img
                                key={index}
                                src={Star}
                                style={{ margin: "0 2px" }}
                                alt=""
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                    {fullname ? (
                      <p className="text1">{fullname}</p>
                    ) : (
                      <p className="text1">{t("breadcrumb.anonim_user")}</p>
                    )}

                    {/* <p className='text2'>Спасибо!</p> */}
                    <span
                      className="text3"
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                    />
                  </SubCard>
                </FourColumnCard>
              ) : null;
            })}
          </>
        ) : null}
      </FourColumnContainer>
    </>
  );
};

export default OurReviews;
