import React, { useEffect, useState } from 'react';
import Product from '../../images/product.png';
import Loved from '../../images/svg/Loved.svg';
import Loved2 from '../../images/svg/Loved2.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  ProductCard,
  ProductContainer,
} from '../../components/GlobalStyledComponents';
import Bag from '../../images/svg/baskett2.svg';
import { useSelector } from 'react-redux';
import { Products } from '../../components/Catalog';
import { getLocalStorage } from '../../helpers/localStorage';
import { useTranslation } from 'react-i18next';
import { GetNotAuthInstance } from '../../helpers/httpClient';

const TopSide = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 776px) and (min-width: 320px) {
    flex-direction: column;
  }
  p {
    font-family: 'Rubik-SemiBold', sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
    @media only screen and (max-width: 776px) and (min-width: 320px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  a {
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #1d4a8e;
  }
`;

const NotFound = styled.div`
  text-align: center;
  margin-bottom: 100px;
  p {
    margin: 20px 0;
  }
  .text1 {
    font-family: 'Rubik-SemiBolds', sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #01304f;
  }
  .text2 {
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #01304f;
    margin-bottom: 40px;
  }
`;

const Featured = () => {
  const { favourite } = useSelector((state) => state);
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const { t, i18n } = useTranslation();

  const filterFavourite = favouriteProducts
    .filter((favouriteProd) => {
      const check = favourite.find((item) => item.id === favouriteProd.id);
      if (check) {
        return favouriteProd;
      }
    })
    .map((item) => {
      const quantity = favourite.find(
        (favouriteProd) => favouriteProd.id === item.id
      );
      if (quantity) {
        return { ...item, quantity: quantity.quantity };
      } else {
        return { ...item, quantity: 1 };
      }
    });

  useEffect(() => {
    const { favourite } = getLocalStorage(['favourite']);
    const favourities = JSON.parse(favourite) || [];
    if (favourities?.length) {
      const url = favourities?.reduce(
        (total, favouriteProducts, index) =>
          total +
          `id=${favouriteProducts?.id}${
            index === favourities.length - 1 ? '' : '&'
          }`,
        'api/v1/product/?'
      );
      GetNotAuthInstance()
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data?.results;
            if (Array.isArray(result)) {
              setFavouriteProducts(result);
            } else {
              setFavouriteProducts([]);
            }
          }
        })
        .catch((e) => {})
        .finally(() => {});
    }
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb>
        <ul className='containerr'>
          <li>
            <Link to='/'>{t('breadcrumb.main')}</Link>
          </li>
          <li>
            <span className='line'> / </span>
          </li>
          <li>
            <span className='disable'>{t('breadcrumb.fav_prod')}</span>
          </li>
        </ul>
      </Breadcrumb>
      <TopSide className='containerr'>
        <p>{t('breadcrumb.fav_prod')}</p>
      </TopSide>
      {filterFavourite.length > 0 ? (
        <ProductContainer>
          {filterFavourite.map((favouriteProduct, index) => (
            <ProductCard key={index}>
              <Products {...favouriteProduct} />
            </ProductCard>
          ))}
        </ProductContainer>
      ) : (
        <NotFound>
          <img src={Bag} style={{ width: '50px', height: '50px' }} alt='' />
          <p className='text1'>{t('fav_page.no_fav')}</p>
          <p className='text2'>{t('fav_page.fav_not_found')}</p>
          <Link to='/catalog' className='appbtn'>
            {t('fav_page.catalog')}
          </Link>
        </NotFound>
      )}
    </>
  );
};

export default Featured;
