import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Logo2 from "../../images/svg/logo2.svg";
import { GetNotAuthInstance } from "../../helpers/httpClient";

const Main = styled.div`
  .card_main {
    margin-top: -32px;
    background: #1d4a8e;
    .card_sub {
      padding: 80px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;

      @media only screen and (max-width: 765px) and (min-width: 320px) {
        flex-direction: column;
        padding: 30px;
      }
      .left {
        width: 100%;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          display: flex;
          justify-content: center;
        }
        img {
          width: 275px;
          height: 85.12px;

          @media only screen and (max-width: 765px) and (min-width: 320px) {
            width: 70%;
          }
        }
      }
      .center {
        color: transparent;
        width: 1px;
        height: 120px;
        background: #1f84c5;
        margin-right: 40px;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          display: none;
        }
      }
      .right {
        font-family: "Rubik-Medium", sans-serif;
        font-size: 24px;
        line-height: 150%;
        color: #ffffff;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        p {
          width: 92%;

          @media only screen and (max-width: 765px) and (min-width: 320px) {
            width: 100%;
            margin-top: 24px;
            text-align: center;
            font-size: 20px;
            line-height: 130%;
          }
        }
      }
    }
  }
  .info {
    margin-top: 50px;
    .title {
      font-family: "Rubik-Medium", sans-serif;
      font-size: 18px;
      line-height: 21px;
      color: #01304f;
    }
    .text {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 16px;
      line-height: 150%;
      color: #6f818f;
      margin-bottom: 36px;
      &:nth-last-child(1) {
        margin-bottom: 50px;
      }
      img {
        object-fit: cover;
      }
    }
  }
  .how_we_work {
    margin-bottom: 60px;
    .title {
      font-family: "Rubik-SemiBold", sans-serif;
      font-size: 32px;
      line-height: 38px;
      color: #01304f;
      margin-bottom: 46px;
    }
    .work_card {
      align-items: start;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, 378px);
      justify-content: center;
      @media (max-width: 1200px) and (min-width: 765px) {
        grid-template-columns: repeat(auto-fit, 363px);
      }

      @media only screen and (max-width: 765px) and (min-width: 320px) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
      .sub {
        p {
          margin: 0;
        }
        span {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: #1f84c5;
          border-radius: 50%;
          font-family: "Rubik-Regular", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
        }
        .text1 {
          font-family: "Rubik-Medium", sans-serif;
          font-size: 18px;
          line-height: 21px;
          margin: 16px 0;
          color: #01304f;
        }
        .text2 {
          font-family: "Rubik-Regular", sans-serif;
          font-size: 16px;
          line-height: 150%;
          color: #01304f;
          margin-bottom: 36px;
        }
      }
    }
  }
`;

const AboutUs = () => {
  const [about, setAbout] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GetNotAuthInstance()
      .get(`api/user/about-company`)
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data[0];
          if (result) {
            setAbout(result);
          } else {
            setAbout({});
          }
        }
      })
      .catch((error) => {
        setAbout({});
      });
    window.scrollTo(0, 0);
  }, [i18n.language]);

  return (
    <>
      <Main>
        <div className="card_main">
          <div className="card_sub containerr_small">
            <div className="left">
              <img src={Logo2} alt="" />
            </div>
            <div className="center">|</div>
            <div className="right">
              <p className="">{about?.title}</p>
            </div>
          </div>
        </div>
        <div className="info containerr_small">
          <p className="title">{t("about_us.title1")}</p>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: about?.about_company?.replaceAll(
                'src="',
                'src="https://api.ama.uz'
              ),
            }}
          />
          <p className="title">{t("about_us.title2")}</p>{" "}
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: about?.about_products?.replaceAll(
                'src="',
                'src="https://api.ama.uz'
              ),
            }}
          />
          <p className="title">{t("about_us.title3")}</p>{" "}
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: about?.about_equipment?.replaceAll(
                'src="',
                'src="https://api.ama.uz'
              ),
            }}
          />
        </div>
        <div className="how_we_work containerr_small">
          <p className="title">{t("about_us.how_we_work")}</p>
          <div className="work_card">
            <div className="sub">
              <span>1</span>
              <p className="text1">{t("about_us.how_we_work_title1")}</p>
              <p className="text2">{about?.how_it_works1}</p>
            </div>
            <div className="sub">
              <span>2</span>
              <p className="text1">{t("about_us.how_we_work_title2")}</p>
              <p className="text2">{about?.how_it_works2}</p>
            </div>
            <div className="sub">
              <span>3</span>
              <p className="text1">{t("about_us.how_we_work_title3")}</p>
              <p className="text2">{about?.how_it_works3}</p>
            </div>
            <div className="sub">
              <span>4</span>
              <p className="text1">{t("about_us.how_we_work_title4")}</p>
              <p className="text2">{about?.how_it_works4}</p>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default AboutUs;
