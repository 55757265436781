import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../../components/GlobalStyledComponents';
import OurContacts from '../../components/OurContacts';

const Contacts = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb>
        <ul className='containerr'>
          <li>
            <Link to='/'>{t('breadcrumb.main')}</Link>
          </li>
          <li>
            <span className='line'> / </span>
          </li>
          <li>
            <span className='disable'>{t('breadcrumb.contacts')}</span>
          </li>
        </ul>
      </Breadcrumb>
      <OurContacts />
    </>
  );
};

export default Contacts;
